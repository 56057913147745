/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { Fetish } from './types'
import {RootState} from '../types';
// import { getSessionStorageService } from '@/services/storage.service'

export const state: Fetish = {
	fetishCategory: [],
	selectedCategory: '',
	fetishModels: [
		// 'Alya', 'Valya', 'Galya', 'Maya'
	],
	nameModel: '',
	fetishModelVideo: [
		// 1,2,3,4,5,6,7,8,9
	],
	itemVideo: 0,
	balance: 1
};

const namespaced: boolean = true;

export const fetish: Module<Fetish, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
