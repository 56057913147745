/* eslint-disable */
import { MutationTree } from 'vuex';
import { Fetish } from '@/store/fetish/types';

export const mutations: MutationTree<Fetish> = {
	setFetishCategory: (state, category: Array<any>) => {
		state.fetishCategory = category.slice();
	},
	setSelectedCategory: (state, category: any) => {
		state.selectedCategory = category;
	},
	setFetishModels: (state, models: Array<any>) => {
		state.fetishModels = models.slice();
	},
	setNameModel: (state, name: string) => {
		state.nameModel = name;
	},
	setFetishModelVideo: (state, video: Array<any>) => {
		state.fetishModelVideo = video.slice();
	},
	setItemVideo: (state, item: number) => {
		state.itemVideo = item;
	}
}
