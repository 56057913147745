/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { Messages } from '@/store/messages/types';
import router from '@/route/routing'

export const actions: ActionTree<Messages, RootState> = {
	fetchTodayMessages: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			commit('setTodayMessages', data);
		}
	},
	fetchHistoryMessages: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			commit('setHistoryMessages', data);
		}
	},
	fetchMessageForReply: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			commit('setMessageForReply', data);
		}
	},
	storeMessages: async ({commit}, message: Object): Promise<any> => {
		const answer = await apiUserData.post('', message)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		// const {data, errors} = answer;
		if(answer.satus) {
			commit('setMessage', message);
		}
	},
};
