/* eslint-disable */
import {Vue} from 'vue-class-component';
import {Action, Getter, Mutation, State, namespace} from 'vuex-class';
import {CustomerState} from '@/store/customer/types';
import {getLocalStorageService, getSessionStorageService, setSessionStorageService, setLocalStorageService} from '@/services/storage.service'
import {ModelAccountState} from '@/store/modelAccount/types';
import {Identifier} from '@intlify/message-compiler';
import router from "@/route/routing";
import $ from "jquery";

const customer = namespace('customer');
const modelState = namespace('modelAccount');
const shop = namespace('shop');


export default class Header extends Vue {
  // urlName: string | undefined | null | symbol = '';
  @modelState.State('modelAccount') modelAccountState: ModelAccountState | undefined;
  @customer.State('customer') customerState: CustomerState | undefined;
  @customer.Mutation('setLanguage') setLanguage: any;
  @modelState.Mutation('setFirstLoad') setFirstLoad: any;
  @modelState.Getter('getFirsLoadState') getFirsLoadState: any;
  @customer.Mutation('setIsLogin') setIsLogin: any;
  @customer.Mutation('saveToken') saveToken: any;
  @customer.Mutation('setRole') setRole: any;
  @customer.Getter('getLanguage') getLanguage: any;
  @customer.Getter('getRole') getRole: any;
  @customer.Getter('getIslogin') getIslogin: any;
  @customer.Getter('getAccountMoney') getAccountMoney: any;
  @customer.Getter('getFetchedUserData') getFetchedUserData: any;
  @customer.Action('fetchUserData') fetchUserData: any;

  @customer.Action('getBasket') getBasket: any;
  @customer.Getter('basket') basket: any;

  $refs!: {
	  mainMenu: HTMLInputElement,
  };

  handleClickMenu = (event: Event & {
	target: HTMLAnchorElement
  }) => {
	const {target} = event;

	if (!getLocalStorageService('confirmAge18'))
	  if (confirm("You are over 18?"))
		  setLocalStorageService('confirmAge18', true);
	  else
		  return;

	$('header').css('z-index', '1500');

	const body = document.querySelector('body');
	const screenWidth = document.documentElement.clientWidth;

	const menu = document.querySelector('.menu-container');
	const menuContent = document.querySelector('.menu');
	const submenu = document.querySelector('.submenu');

	//@ts-ignore
	if(menu.classList.contains('show-flex')){
	  //@ts-ignore
	  menu.classList.replace('show-flex', 'hidden');
	} else{
	  //@ts-ignore
	  menu.classList.add('show-flex');
	}

	//@ts-ignore
	if(menuContent.classList.contains('show-flex')){
	  //@ts-ignore
	  menuContent.classList.replace('show-flex', 'hidden');
	}
	//@ts-ignore
	else if (menuContent.classList.contains('hidden')){
	  //@ts-ignore
	  menuContent.classList.replace('hidden','show-flex');
	} else{
	  //@ts-ignore
	  menuContent.classList.add('show-flex');
	}

	//@ts-ignore
	body.style.overflow = 'hidden';

	if(screenWidth >= 768){
	  const menuItemsFirstEl = document.querySelectorAll('.menu-item')[0];
	  const submenuItemsFirstEl = document.querySelectorAll('.submenu-item')[0];
	  //@ts-ignore
	  body.style.overflow = 'hidden';
	  //menuItemsFirstEl.classList.add('active');
	  submenuItemsFirstEl.classList.replace('hidden', 'show');
	} else if(screenWidth < 768){
	  //@ts-ignore
	  submenu.classList.add('hidden');
	}

      //$('#webModels').click();
  }

  handleClickMenuItem = (event: Event & {
	target: HTMLAnchorElement
  }) => {
	const {target} = event;

	const screenWidth = document.documentElement.clientWidth;

	const menuContent = document.querySelector('.menu');
	const submenu = document.querySelector('.submenu');
	const menuItems = document.querySelectorAll('.menu-item');
	const submenuItems = document.querySelectorAll('.submenu-item');

	const submenuItemId = target.id + 'Content';
	const submenuItemIdTarget = document.querySelector(`#${submenuItemId}`);

	if(target.classList.contains('menu-item')){
	  menuItems.forEach(item => {
		item.className = item.className.replace('active', '');
	  });

	  submenuItems.forEach(item => {
		item.classList.remove('show');
		item.classList.add('hidden');
	  });
	  if(screenWidth >= 768){
		//@ts-ignore
		submenuItemIdTarget.classList.replace('hidden', 'show');

		target.className += ' active';
	  } else if (screenWidth < 768){
		//@ts-ignore
		menuContent.classList.replace('show-flex', 'hidden');

		//@ts-ignore
		submenu.classList.replace('hidden', 'show');

		//@ts-ignore
		submenuItemIdTarget.classList.replace('hidden', 'show');
	  }
	}

	if(target.classList.contains('submenu-link-btn')){
	  //@ts-ignore
	  const linkUrl = target.getAttribute('href').toString();
	  window.location.href = linkUrl;
	}
  }

  handleClickGoBack = (event: Event & {
	target: HTMLAnchorElement
  }) => {
	const {target} = event;

	const menuContent = document.querySelector('.menu');
	const submenu = document.querySelector('.submenu');
	const submenuItems = document.querySelectorAll('.submenu-item');
	const menuItems = document.querySelectorAll('.menu-item');

	menuItems.forEach(item => {
	  item.className = item.className.replace('active', '');
	});

	submenuItems.forEach(item => {
	  item.classList.remove('show');
	});

	//@ts-ignore
	submenu.classList.replace('show', 'hidden');

	//@ts-ignore
	menuContent.classList.replace('hidden', 'show-flex');
  }

  handleClickCloseMenu = (event: Event & {
	target: HTMLAnchorElement
  }) => {
	const {target} = event;

	$('header').removeAttr('style');

	const body = document.querySelector('body');

	const menu = document.querySelector('.menu-container');
	const menuContent = document.querySelector('.menu');
	const submenu = document.querySelector('.submenu');
	const submenuItems = document.querySelectorAll('.submenu-item');
	const menuItems = document.querySelectorAll('.menu-item');

	submenuItems.forEach(item => {
	  item.classList.replace('show', 'hidden');
	});
	menuItems.forEach(item => {
	  item.className = item.className.replace('active', '');
	});

	//@ts-ignore
	menu.classList.remove('show-flex');

	//@ts-ignore
	menuContent.classList.replace('show-flex', 'hidden');

	//@ts-ignore
	submenu.classList.replace('show', 'hidden');

	//@ts-ignore
	body.style.overflow = 'auto';
  }


  getUserLang(event: Event & {
	target: HTMLAnchorElement
  }) {
	const {target} = event;
	if (target.innerText === 'English') {
	  this.setLanguage('en');
	  this.$i18n.locale = this.getLanguage;
	}
	if (target.innerText === 'Русский') {
	  this.setLanguage('ru');
	  this.$i18n.locale = this.getLanguage;
	}
  }

	  enterUserAccount() {
		  var _this = this;

		  var role: any = getLocalStorageService('role');

		  if (role == 3)
		  {
			  console.log('Отображение главной аккаунта пользователя');
			  this.$router.push({name: 'user-account'}).then(function () {
				  router.go(<any>router.currentRoute);
			  });
			  return;
		  }
		  else if (role == 4)
		  {
			  console.log('Отображение главной аккаунта модели');
			  this.$router.push({name: 'model-account-my-account'}).then(function () {
				  router.go(<any>router.currentRoute);
			  });
		  }
		  else
			console.log('ERROR: enterUserAccount()');
	  }

	logout()
	{
		sessionStorage.clear();
		localStorage.clear();
		this.setIsLogin(false);
		router.push({name: 'main'}).then(r => r);
	}

    get totalPrice() {
  		if (this.basket.length)
        	return this.basket.reduce((acc : any, item : any) => acc + (item.price * item.count), 0);
        else
            return 0;
    }

    get totalAmount() {
        if (this.basket.length)
       		return this.basket.reduce((acc : any, item : any) => acc + item.count, 0);
        else
        	return 0;
    }

    ProductImage(product : any)
    {
        return "background-image: url('" + product.image + "')";
    }

    BasketMenu(e : any)
	{
		let basket = $('#js-menu-basket');

        this.UpdateBasketPos(false);

		if (basket.is(':visible'))
		{
            basket.slideUp(250);
		}
		else
		{
            basket.slideDown(250);
		}
	}

	UpdateBasketPos(resize : any = true)
	{
        let icon = $(".js-basket-menu").find("> *:first")
        let basket = $('#js-menu-basket');

        if (resize && basket.is(":visible"))
		{
            if (icon.is(":visible"))
                basket.show();
            else
                basket.hide();
		}

        let iconPos : any = icon.offset();
        basket.css('top', iconPos.top + 45);
        basket.css('left', iconPos.left);
	}

	mounted() {
        let _this = this;

		console.log("Header mounted()");

		//console.log(urlParams.has('menu')); // true
		//console.log(urlParams.get('menu')); // "MyParam"
		let urlParams = new URLSearchParams(window.location.search);

		if (urlParams.has('token'))
		{
			let token = urlParams.get('token');
			this.saveToken(token);
		}

		if (urlParams.has('menu'))
		{
			console.log("Открываем меню");
			const elem = this.$refs.mainMenu;
			elem.click();
		}

		this.fetchUserData().then(() => {
			if (this.getFetchedUserData) {
				this.setIsLogin(true);
			}
		});

        this.getBasket();

        window.addEventListener('resize', this.UpdateBasketPos);
	}
}
