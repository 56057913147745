/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { SecretDesire } from '@/store/secretDesire/types';

export const actions: ActionTree<SecretDesire, RootState> = {
	storeSelectedModel: ({commit}, models: Array<any>) => {
		commit('setSelectedModels', models);
	},

	fetchSecretDesireModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/secret-wish/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		if(answer.data) {
			commit('setListModels', answer.data);
		}
	},
	storeSecretDesireModels: async ({commit}, secretDesire: Object): Promise<any> => {
		const answer = await apiUserData.post('/secret-wish/add-request', secretDesire)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		if(answer.status) {
			console.log('Great!');
		}
	},
};
