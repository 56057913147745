/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { Support } from '@/store/support/types';

export const actions: ActionTree<Support, RootState> = {
	storeSupportRequest: async ({commit}, request: Object): Promise<any> => {
		const answer = await apiUserData.post('/support/add-request', request)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) console.log('Great!');
	}
};
