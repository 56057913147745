/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { SexMasterClass } from '@/store/sexMasterClass/types'

export const getters: GetterTree<SexMasterClass, RootState> = {
	getMasterClassPriceVideo: (state) => {
		return state.priceVideo;
	},
	getModels: state => {
		return state.allModels;
	}
}
