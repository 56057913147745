/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { BDSM } from './types'
import {RootState} from '../types';
// import { getSessionStorageService } from '@/services/storage.service'

export const state: BDSM = {
	timeLeft: '',
	moneyLeft: 0,
	arrVideo: [
		// 1,2,3,4,5,6,7,8,9,10,11,12
	]
};

const namespaced: boolean = true;

export const bdsm: Module<BDSM, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
