/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { PPWebModels } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service';

export const state: PPWebModels = {
	ppModels: [
		// {
		// 	id: 0,
		// 	name: 'Valya',
		// },
		// {
		// 	id: 1,
		// 	name: 'Vika',
		// },
		// {
		// 	id: 2,
		// 	name: 'Olga',
		// },
		// {
		// 	id: 3,
		// 	name: 'Elena',
		// },
		// {
		// 	id: 4,
		// 	name: 'Katya',
		// },
		// {
		// 	id: 5,
		// 	name: 'Valya',
		// },
		// {
		// 	id: 6,
		// 	name: 'Vika',
		// },
		// {
		// 	id: 7,
		// 	name: 'Olga',
		// },
		// {
		// 	id: 8,
		// 	name: 'Elena',
		// },
		// {
		// 	id: 9,
		// 	name: 'Katya',
		// },
		// {
		// 	id: 10,
		// 	name: 'Elena',
		// },
		// {
		// 	id: 11,
		// 	name: 'Katya',
		// },
	],
	currentModel: {},
	messages: [
		// {
		// 	id: 0,
		// 	username: 'Valery',
		// 	message: 'If you wanna be able to chat with me and discover more, just make a free account! xox'
		// },
		// {
		// 	id: 1,
		// 	username: 'Valery',
		// 	message: 'Make me playfull while you soak up my pussy!'
		// },
	],
	currentViewProduct: {
		// id: 0,
		// img: 'file1.png',
		// name: 'Бюстгальтер',
		// price: 300
	},
	cardModelInfo: {

	},
	modelServiceCard: [
		// {
		// 	id: 0,
		// 	name: 'PUSSY',
		// 	price: 20
		// },
		// {
		// 	id: 1,
		// 	name: 'NAKED ASS',
		// 	price: 20
		// },
		// {
		// 	id: 2,
		// 	name: 'FINGERING ASS',
		// 	price: 20
		// },
		// {
		// 	id: 3,
		// 	name: 'FUCKING  PUSSY WITH A DILDO',
		// 	price: 20
		// },
		// {
		// 	id: 4,
		// 	name: 'LUSH and DOMI 333 SEC',
		// 	price: 20
		// },
		// {
		// 	id: 5,
		// 	name: 'FUCKING ASS WITH A DILDO',
		// 	price: 20
		// },

	],
	isSwitchChat: false,
	modelInfo: []
};

const namespaced: boolean = true;

export const ppWebModels: Module<PPWebModels, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
