/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { SexMasterClass } from '@/store/sexMasterClass/types';

export const mutations: MutationTree<SexMasterClass> = {
	setStateAllModels: (state, arrModels: Array<any>) => {
		state.allModels = arrModels;
	},
	setPriceVideo: (state, price: number) => {
		state.priceVideo = price;
		setSessionStorageService('priceVideo', price);
	}
}
