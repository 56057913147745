/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { SecretDesire } from '@/store/secretDesire/types';

export const mutations: MutationTree<SecretDesire> = {
	setListModels: (state, arrModels: Array<any>) => {
		state.arrAllModels = arrModels.slice();
	},
	setNumOfModels: (state, number: number) => {
		state.numOfModels = number;
		setSessionStorageService('numOfModels', number);
	},
	setSelectedModelLength: (state, length: number) => {
		state.selectedModels.length = length;
	},
	setSelectedModels: (state, models: Array<any>) => {
		state.selectedModels = models.slice();
		setSessionStorageService('selectedModels', JSON.stringify(models));
	}
}
