/* eslint-disable */
import Vuex, {StoreOptions} from "vuex";
import {RootState} from "@/store/types";
import {customer} from "@/store/customer";
import {translate} from "@/store/translate";
import {payments} from "@/store/payments";
import {modelAccount} from "@/store/modelAccount";
import {hisOwnDirector} from '@/store/hisOwnDirector';
import {sexMasterClass} from '@/store/sexMasterClass';
import {worldsWebModels} from '@/store/worldsWebModels';
import {asmr} from '@/store/asmr';
import {fetish} from '@/store/fetish';
import {bdsm} from '@/store/bdsm';
import {shop} from '@/store/shop';
import {secretDesire} from '@/store/secretDesire';
import {videoStories} from '@/store/videoStories';
import {sexRealityShow} from '@/store/sexRealityShow';
import {tariff} from '@/store/tariff';
import {ppWebModels} from '@/store/ppWebModels';
import {support} from '@/store/support';
import {modelOnWork} from '@/store/modelOnWork';
import {messages} from '@/store/messages';

const store: StoreOptions<RootState> = {
    state () {},
	actions: {},
    modules: {
        customer,
		translate,
		payments,
		modelAccount,
		hisOwnDirector,
		sexMasterClass,
		worldsWebModels,
		asmr,
		fetish,
		bdsm,
		shop,
		secretDesire,
		videoStories,
		sexRealityShow,
		tariff,
		ppWebModels,
		support,
		modelOnWork,
		messages
    }
}

export default new Vuex.Store<RootState>(store);

