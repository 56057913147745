/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {ModelAccountState} from './types'
import {RootState} from '../types';
import { getLocalStorageService, getSessionStorageService } from '@/services/storage.service'


export const state: ModelAccountState = {
	isRememberPassword: false,
	isRequestStatus: false,
	firstLoad: getLocalStorageService('firstLoad') ?? 'false',
	login: getLocalStorageService('login') || getSessionStorageService('login') || '',
	modelData: {
		email: getSessionStorageService('email') || '',
		name: getSessionStorageService('name') || '',
		lastName:  getSessionStorageService('lastName') || '',
		phone: getSessionStorageService('phone') || '',
		country: getSessionStorageService('country') || '',
		region: getSessionStorageService('region') || '',
		city: getSessionStorageService('city') || '',
		address: getSessionStorageService('address') || '',
		postcode: getSessionStorageService('postcode') || '',
		dcmaProtect: getSessionStorageService('dcmaProtect') || true,
		emailNotification: getSessionStorageService('emailNotification') || true,
		specialOffers: getSessionStorageService('specialOffers') || true,
		documentPhoto: getSessionStorageService('documentPhoto') || '',
		facePhoto: getSessionStorageService('facePhoto') || '',
		faceInDocPhoto:  getSessionStorageService('faceInDocPhoto') || '',
		documentAvailable:  getSessionStorageService('documentAvailable') || 0,
		birthday: getSessionStorageService('birthday') || 0
	},
	modelSettings: {
		username: getSessionStorageService('biusernamerthday') || '',
		age:  JSON.parse(<string>getSessionStorageService('age')) || 0,
		ethnicOrigin:  getSessionStorageService('ethnicOrigin') || '',
		sexualPreferences: getSessionStorageService('sexualPreferences') ||  '',
		nativeLanguage:  getSessionStorageService('nativeLanguage') || '',
		moreLanguage:  getSessionStorageService('moreLanguage') || '',
		country:  getSessionStorageService('country') || '',
		city: getSessionStorageService('city') ||  '',
		growth:  getSessionStorageService('growth') || '',
		bodyType:  getSessionStorageService('bodyType') || '',
		eyeColor:  getSessionStorageService('eyeColor') || '',
		buttSize:  getSessionStorageService('buttSize') || '',
		weight: getSessionStorageService('weight') ||  '',
		hairColor:  getSessionStorageService('hairColor') || '',
		breastSize: getSessionStorageService('breastSize') ||  '',
		intimateHaircut:  getSessionStorageService('intimateHaircut') || '',
		about: getSessionStorageService('about') ||  '',
		image: getSessionStorageService('image') ||  '',
		tags: []
	},
	stateAgreement: JSON.parse(<string>getSessionStorageService('agreement')) || true,
	modelsPhotos: [],
	modelsVideo: [],
	dataConfidentiality: {},
	message: '',
	role:  JSON.parse(<string>getSessionStorageService('role')) || 0,
	isLogin: JSON.parse(<string>getSessionStorageService('isLogin')) || false,
    formValues: [],
	data: {},
    countries: [],
    regions: [],
	cities: [],
    allLangs: [],
    modelInfo: [],
    modelTags: [],
    shopCategories: [],
    modelProducts: []
};

const namespaced: boolean = true;

export const modelAccount: Module<ModelAccountState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
