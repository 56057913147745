/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { Messages } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service';

export const state: Messages = {
	todayMessages: [
		{
			id: 0,
			date: '02/10/2021',
			time: '21:13',
			message: 'Ваша заявка на тайное желание одобрена. Стоимость составит 1500 PPSD. Срок реализации — 2 недели.'
		},
		{
			id: 1,
			date: '02/10/2021',
			time: '18:01',
			message: 'Ответ из службы поддержки: Ваша заявка на тайное желание одобрена. Стоимость составит 1500 PPSD. Срок реализации — 2 недели.'
		},
	],
	historyMessages: [
		{
			id: 0,
			date: '02/10/2021',
			msgs: [
				{
					id: 0,
					time: '21:13',
					message: 'Ваша заявка на тайное желание одобрена. Стоимость составит 1500 PPSD. Срок реализации — 2 недели.'
				},
				{
					id: 1,
					time: '16:01',
					message: 'Ответ из службы поддержки: Чтобы пополнть счёт необходимо перейти в личный кабинет, для этого достаточно кликнуть на аватар, а затем кликнуть по ссылке «личный кабинет» в выпавшем окне.'
				},
				{
					id: 2,
					time: '18:01',
					message: 'Ваш обращение в службу поддержки успешно создано, пожалуйста, дождитесь ответа.  Все входящие обращения, направленные через форму обратной связи, будут рассмотрены службой поддержки за время от 1 до 24 часов с момента размещения в зависимости от приоритета.<br><br>Пользователю на e-mail адрес, указанный в форме обратной связи, будет направлено уведомление с номером обращения. При необходимости службой поддержки будет запрошена дополнительная информация. Все дальнейшие уведомления о статусе обработки обращений направляются пользователям с номером обращения в теме письма.'
				},
			],

		},
		{
			id: 1,
			date: '02/09/2021',
			msgs: [
				{
					id: 0,
					time: '21:13',
					message: 'Ваша заявка на тайное желание одобрена. Стоимость составит 1500 PPSD. Срок реализации — 2 недели.'
				},
				{
					id: 1,
					time: '16:01',
					message: 'Ответ из службы поддержки: Чтобы пополнть счёт необходимо перейти в личный кабинет, для этого достаточно кликнуть на аватар, а затем кликнуть по ссылке «личный кабинет» в выпавшем окне.'
				},
				{
					id: 2,
					time: '18:01',
					message: 'Ваш обращение в службу поддержки успешно создано, пожалуйста, дождитесь ответа.  Все входящие обращения, направленные через форму обратной связи, будут рассмотрены службой поддержки за время от 1 до 24 часов с момента размещения в зависимости от приоритета.<br><br>Пользователю на e-mail адрес, указанный в форме обратной связи, будет направлено уведомление с номером обращения. При необходимости службой поддержки будет запрошена дополнительная информация. Все дальнейшие уведомления о статусе обработки обращений направляются пользователям с номером обращения в теме письма.'
				},
			],

		},
		{
			id: 2,
			date: '02/08/2021',
			msgs: [
				{
					id: 0,
					time: '21:13',
					message: 'Ваша заявка на тайное желание одобрена. Стоимость составит 1500 PPSD. Срок реализации — 2 недели.'
				},
				{
					id: 1,
					time: '16:01',
					message: 'Ответ из службы поддержки: Чтобы пополнть счёт необходимо перейти в личный кабинет, для этого достаточно кликнуть на аватар, а затем кликнуть по ссылке «личный кабинет» в выпавшем окне.'
				},
				{
					id: 2,
					time: '18:01',
					message: 'Ваш обращение в службу поддержки успешно создано, пожалуйста, дождитесь ответа.  Все входящие обращения, направленные через форму обратной связи, будут рассмотрены службой поддержки за время от 1 до 24 часов с момента размещения в зависимости от приоритета.<br><br>Пользователю на e-mail адрес, указанный в форме обратной связи, будет направлено уведомление с номером обращения. При необходимости службой поддержки будет запрошена дополнительная информация. Все дальнейшие уведомления о статусе обработки обращений направляются пользователям с номером обращения в теме письма.'
				},
			],

		}
	],
	messageOnReply: [
		{
			id: 0,
			time: '20:00',
			date: '01/10/2021',
			from: 'Меня',
			message:` Сформирован запрос на Тайное желание.
				 Количество моделей: 2 (Bazhena, Masha666)
				 Время видео: 60 секунд
				 Текст желания: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed libero risus, sagittis eu velit a, interdum pharetra massa. Vivamus ac turpis sed mauris luctus dignissim at et odio. Mauris imperdiet sit amet est eget mattis. Vivamus quis augue eu dui sagittis varius. Etiam sem lectus, ullamcorper in convallis malesuada, dictum eu lacus. Sed lectus ex, vulputate vitae posuere nec, bibendum quis ligula. Cras mollis quis nunc sed tempus. Donec ornare, elit et tincidunt placerat, mi sapien pulvinar leo, vel venenatis tellus ipsum at tellus. Quisque eu mi lorem.`
		},
		{
			id: 1,
			time: '20:00',
			date: '01/10/2021',
			from: 'P&P',
			message: `Ответ на ваш запрос в службу поддержки:
				Как мне заказать Тайное желание?
				Здравствуйте, для заказа Тайного желания заполните все данные в разделе «Тайное желание». Мы рассмотрим возможность реализации вашего Тайного желания и дадим вам ответ в сообщении.`
		},
		{
			id: 2,
			time: '20:00',
			date: '01/10/2021',
			from: 'Меня',
			message: `Как мне заказать Тайное желание?`
		}
	],
	lastMessage: {}
};

const namespaced: boolean = true;

export const messages: Module<Messages, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
