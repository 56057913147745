/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { SexRealityShow } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: SexRealityShow = {
	realityShowModels: [
		// {
		// 	id: 0,
		// 	name: 'Valya',
		// 	count: 100008
		// },
		// {
		// 	id: 1,
		// 	name: 'Vika',
		// 	count: 100002
		// },
		// {
		// 	id: 2,
		// 	name: 'Olga',
		// 	count: 100003
		// },
		// {
		// 	id: 3,
		// 	name: 'Elena',
		// 	count: 100004
		// },
		// {
		// 	id: 4,
		// 	name: 'Katya',
		// 	count: 100005
		// },
	],
	archivesVideo: [
		// {
		// 	id: 1,
		// 	name: 'Video1'
		// },
		// {
		// 	id: 2,
		// 	name: 'Video2'
		// },	{
		// 	id: 3,
		// 	name: 'Video3'
		// },	{
		// 	id: 4,
		// 	name: 'Video4'
		// },	{
		// 	id: 5,
		// 	name: 'Video5'
		// },	{
		// 	id: 6,
		// 	name: 'Video6'
		// },	{
		// 	id: 7,
		// 	name: 'Video7'
		// },	{
		// 	id: 8,
		// 	name: 'Video8'
		// },	{
		// 	id: 9,
		// 	name: 'Video9'
		// },	{
		// 	id: 10,
		// 	name: 'Video10'
		// },	{
		// 	id: 11,
		// 	name: 'Video11'
		// },	{
		// 	id: 12,
		// 	name: 'Video12'
		// },
	],
	myCount: 100
};

const namespaced: boolean = true;

export const sexRealityShow: Module<SexRealityShow, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
