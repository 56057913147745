/* eslint-disable */
import { MutationTree } from 'vuex';
import { ModelAccountState } from '@/store/modelAccount/types';
import { setLocalStorageService , setSessionStorageService} from '@/services/storage.service'

export const mutations: MutationTree<ModelAccountState> = {
	modelRememberPassword: (state, rememberPassword: boolean) => {
		const rememberPasswordJson: string = JSON.stringify(rememberPassword);
		if (rememberPassword) setLocalStorageService('rememberPassword', rememberPasswordJson);
		state.isRememberPassword = rememberPassword;
	},
	setFirstLoad: (state, firstLoad: string) => {
		state.firstLoad = firstLoad;
		setLocalStorageService('firstLoad', firstLoad);
	},
	setModelLogin: (state, login: string) => {
		state.login = login;
		setLocalStorageService('login', login);
	},
	saveToken: (state, token: string) => {
		if (state.isRememberPassword) {
			setLocalStorageService('token', token);
			setSessionStorageService('token', token);

		} else {
			setSessionStorageService('token', token);
		}
	},
	setRole: (state, role: number) => {
		state.role = role;
		setSessionStorageService('role', role);
	},
	setIsLogin: (state, loginState: boolean) => {
		const isLoginState: string = JSON.stringify(loginState)
		if(loginState) setSessionStorageService('isLogin', isLoginState);
        if(loginState) setLocalStorageService('isLogin', isLoginState);

		state.isLogin = loginState;
	},
	setLogin: (state, login: string) => {
		state.login = login;
		if(state.isLogin){
			setLocalStorageService('login', login);
		} else {
			setSessionStorageService('login', login)
		}
	},
	setModelAgreement: (state, modelAgreement: boolean) => {
		state.stateAgreement = modelAgreement;
		setLocalStorageService('modelAgreement', modelAgreement);
	},
	setModelsPhotos: (state, arrModelPhoto: Array<string>) => {
		state.modelsPhotos = arrModelPhoto;
	},
	setRequestStatus: (state, status: boolean) => {
		state.isRequestStatus = status;
	},
	setErrorMessage: (state, message: string) => {
		state.message = message;
	},

//=============== Model Data ===============

	setModelEmail: (state, email: string) => {
		state.modelData.email = email;
		setSessionStorageService('email', email);
	},
	setModelName: (state, name: string) => {
		state.modelData.name = name;
		setSessionStorageService('name', name);
	},
	setModelLastName: (state, lastName: string) => {
		state.modelData.lastName = lastName;
		setSessionStorageService('lastName', lastName);
	},
	setModelPhone: (state, phone: string) => {
		state.modelData.phone = phone;
		setSessionStorageService('phone', phone);
	},
	setModelCountry: (state, country: string) => {
		state.modelData.country = country;
		setSessionStorageService('country', country);
	},
	setModelRegion: (state, region: string) => {
		state.modelData.region = region;
		setSessionStorageService('region', region);
	},
	setModelCity: (state, city: string) => {
		state.modelData.city = city;
		setSessionStorageService('city', city);
	},
	setModelAddress: (state, address: string) => {
		state.modelData.address = address;
		setSessionStorageService('address', address);
	},
	setModelPostcode: (state, postcode: string) => {
		state.modelData.postcode = postcode;
		setSessionStorageService('postcode', postcode);
	},
	setModelDCMAProtect: (state, dcmaProtect: number) => {
		if(dcmaProtect) {
			state.modelData.dcmaProtect = true;
			setSessionStorageService('dcmaProtect', true);
		}else {
			state.modelData.dcmaProtect = false;
			setSessionStorageService('dcmaProtect', false);
		}
	},
	setModelEmailNotification: (state, emailNotification: number) => {
		if(emailNotification) {
			state.modelData.emailNotification = true;
			setSessionStorageService('emailNotification', true);
		}else {
			state.modelData.emailNotification = false;
			setSessionStorageService('emailNotification', false);
		}
	},
	setModelSpecialOffers: (state, specialOffers: number) => {
		if(specialOffers) {
			state.modelData.specialOffers = true;
			setSessionStorageService('specialOffers', true);
		}else {
			state.modelData.specialOffers = false;
			setSessionStorageService('specialOffers', false);
		}
	},
	setModelDocumentPhoto: (state, documentPhoto: string) => {
		state.modelData.documentPhoto = documentPhoto;
		setSessionStorageService('documentPhoto', documentPhoto);
	},
	setModelFacePhoto: (state, facePhoto: string) => {
		state.modelData.facePhoto = facePhoto;
		setSessionStorageService('facePhoto', facePhoto);
	},
	setModelFaceInDocPhoto: (state, faceInDocPhoto: string) => {
		state.modelData.faceInDocPhoto = faceInDocPhoto;
		setSessionStorageService('faceInDocPhoto', faceInDocPhoto);
	},
	setModelDocumentAvailable: (state, documentAvailable: number) => {
		state.modelData.documentAvailable = documentAvailable;
		setSessionStorageService('documentAvailable', documentAvailable);
	},
	setModelBirthday: (state, birthday: number) => {
		state.modelData.birthday = birthday;
		setSessionStorageService('birthday', birthday);
	},
// ======= Model Settings Mutations ====

	setModelSettingsUsernaqme: (state, username: string) => {
		state.modelSettings.username = username;
		setSessionStorageService('username', username);
	},
	setModelSettingsAge: (state, age: number) => {
		state.modelSettings.age = age;
		setSessionStorageService('username', age);
	},
	setModelSettingsEthnicOrigin: (state, ethnicOrigin: string) => {
		state.modelSettings.ethnicOrigin = ethnicOrigin;
		setSessionStorageService('ethnicOrigin', ethnicOrigin);
	},
	setModelSettingsSexualPreferences: (state, sexualPreferences: string) => {
		state.modelSettings.sexualPreferences = sexualPreferences;
		setSessionStorageService('sexualPreferences', sexualPreferences);
	},
	setModelSettingsNativeLanguage: (state, nativeLanguage: string) => {
		state.modelSettings.nativeLanguage = nativeLanguage;
		setSessionStorageService('nativeLanguage', nativeLanguage);
	},
	setModelSettingsMoreLanguage: (state, moreLanguage: string) => {
		state.modelSettings.moreLanguage = moreLanguage;
		setSessionStorageService('moreLanguage', moreLanguage);
	},
	setModelSettingsCountry: (state, country: string) => {
		state.modelSettings.country = country;
		setSessionStorageService('country', country);
	},
	setModelSettingsCity: (state, city: string) => {
		state.modelSettings.city = city;
		setSessionStorageService('city', city);
	},
	setModelSettingsGrowth: (state, growth: string) => {
		state.modelSettings.growth = growth;
		setSessionStorageService('growth', growth);
	},
	setModelSettingsBodyType: (state, bodyType: string) => {
		state.modelSettings.bodyType = bodyType;
		setSessionStorageService('bodyType', bodyType);
	},
	setModelSettingsEyeColor: (state, eyeColor: string) => {
		state.modelSettings.eyeColor = eyeColor;
		setSessionStorageService('eyeColor', eyeColor);
	},
	setModelSettingsButtSize: (state, buttSize: string) => {
		state.modelSettings.buttSize = buttSize;
		setSessionStorageService('buttSize', buttSize);
	},
	setModelSettingsWeight: (state, weight: string) => {
		state.modelSettings.weight = weight;
		setSessionStorageService('weight', weight);
	},
	setModelSettingsHairColor: (state, hairColor: string) => {
		state.modelSettings.hairColor = hairColor;
		setSessionStorageService('hairColor', hairColor);
	},
	setModelSettingsBreastSize: (state, breastSize: string) => {
		state.modelSettings.breastSize = breastSize;
		setSessionStorageService('breastSize', breastSize);
	},
	setModelSettingsIntimateHaircut: (state, intimateHaircut: string) => {
		state.modelSettings.intimateHaircut = intimateHaircut;
		setSessionStorageService('intimateHaircut', intimateHaircut);
	},
	setModelSettingsAbout: (state, about: string) => {
		state.modelSettings.about = about;
		setSessionStorageService('about', about);
	},
	setModelSettingsImage: (state, image: string) => {
		state.modelSettings.image = image;
		setSessionStorageService('image', image);
	},
	setModelSettingsTags: (state, tags: Array<any>) => {
		state.modelSettings.tags = tags;
		//setSessionStorageService('tags', tags);
	},
	setConfidentiality: (state, dataConfidentiality) => {
		state.dataConfidentiality = dataConfidentiality;
	},
	setModelPhoto: (state, modelPhoto: Array<any>) => {
		state.modelsPhotos = modelPhoto;
	},
	setModelVideo: (state, modelVideo: Array<any>) => {
		state.modelsVideo = modelVideo;
	},
    setFormValues: (state, formValues: any) => {
        state.formValues = formValues;
    },
    setModelData: (state, data: any) => {
        state.data = data;
    },
    setCountriesData: (state, data: any) => {
        state.countries = data;
    },
    setRegionsData: (state, data: any) => {
        state.regions = data;
    },
    setCitiesData: (state, data: any) => {
        state.cities = data;
    },
    clearCountriesData: (state) => {
        state.countries = [];
    },
    clearRegionsData: (state) => {
        state.regions = [];
    },
    clearCitiesData: (state) => {
        state.cities = [];
    },
    setAllLangs: (state, data: any) => {
        state.allLangs = data;
    },
    setModelInfo: (state, data: any) => {
        state.modelInfo = data;
    },
    setModelTags: (state, data: any) => {
        state.modelTags = data;
    },
    setShopCategories: (state, data: any) => {
        state.shopCategories = data;
    },
    setModelProducts: (state, data: any) => {
        state.modelProducts = data;
    },
}
