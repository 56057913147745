/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import apiUserData  from '@/services/getUserData.service';
import { Shop } from '@/store/shop/types'
// import api from '@/services/api.service';
import router from '@/route/routing';

export const actions: ActionTree<Shop, RootState> = {
    getShopModels:  async ({commit}): Promise<any> => {
        const answer = await apiUserData.get('/shop/get-models').then((response) => {
			return response;
		});

        if (answer)
            commit('setShopModels', answer.data);
    },

    getDeliveryCountries: async ({commit}): Promise<any> => {
        const answer = await apiUserData.get('/shop/get-countries').then(response => {

           if (response.status)
               commit('setDeliveryCountries', response.data);

            return response;
        });

        if (answer)
            return answer.data;
    },

	getDeliveryTypes: async ({commit}, id : number): Promise<any> => {
        const answer = await apiUserData.get('/shop/get-delivery/'+id).then(response => {
            return response;
        });

        if (answer)
            return answer.data;
    },


	fetchModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/shop/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer;
		if(answer.status) {
			commit('setArrModel', data);
		}
		if(errors.length > 0) console.log(errors);
	},
	fetchModelProducts: async ({commit}, model): Promise<any> => {
		const answer = await apiUserData.get(`/shop/get-model-products/?id=${model}`)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer;
		if(answer.status && data.length > 0) {
			commit('setModelProducts', data);
		}
		if(errors.length > 0) console.log(errors);
	},
	addToBasket:  async ({commit}, product: Object): Promise<any> => {
		const answer = await apiUserData.post('/shop/add-to-basket', product)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
	// 	const {data, errors} = answer;
	// 	if(answer.status) {
	//
	// 	}
	// 	if(errors) console.log(errors);
	},
	addOrder:  async ({commit}, order: Object): Promise<any> => {
		const answer = await apiUserData.post('/shop/add-order', order)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer;
		if(answer.status) {
			router.push({ name: 'info' }).then(r => r);
		}
		if(errors) console.log(errors);
	},
	fetchCountry:  async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/shop/get-countries')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer;
		if(answer.status) {
			commit('setDeliveryCountry', data);
		}
		if(errors) console.log(errors);
	},
};
