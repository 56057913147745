/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { VideoStories } from '@/store/videoStories/types';

export const actions: ActionTree<VideoStories, RootState> = {
	fetchVideoStoriesModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/fairy-tales/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(answer.data) {
			commit('setListModels', data);
		}
	},
	fetchModelsVideo: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/fairy-tales/get-videos')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(answer.data) {
			commit('setVideoList', data);
		}
	},
	payVideo: async ({commit}, pay): Promise<any> => {
		const answer = await apiUserData.post('', pay)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(answer.data) {
			console.log('Great!');
		}
	},
};
