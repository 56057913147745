/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { Messages } from '@/store/messages/types';

export const getters: GetterTree<Messages, RootState> = {
	getTodayMessages: state => {
		return state.todayMessages;
	},
	getHistoryMessages: state => {
		return state.historyMessages;
	},
	getMessageForReply: state => {
		return state.messageOnReply;
	},
	getLastMessage: state => {
		return state.lastMessage;
	}
}
