/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { ModelOnWork } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: ModelOnWork = {
	messages: [
		{
			id: 0,
			username: 'Valery',
			message: 'If you wanna be able to chat with me and discover more, just make a free account! xox'
		},
		{
			id: 1,
			username: 'Valery',
			message: 'Make me playfull while you soak up my pussy!'
		},
	],
	selectedAction: [],
	idStream: "",
	chatMessages: [],
	modelActions: [],
};

const namespaced: boolean = true;

export const modelOnWork: Module<ModelOnWork, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
