/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { ModelOnWork } from '@/store/modelOnWork/types';

export const mutations: MutationTree<ModelOnWork> = {
	setMessageToChat: (state, message: Array<any>) => {
		state.messages.concat(message);
	},
	setAction: (state, action: Array<any>) => {
		state.selectedAction = action.slice();
	},
    setStreamID: (state, id : any) => {
        state.idStream = id;
    },
    setChatMessages: (state, data : any) => {
        state.chatMessages = data;
    },
    setModelActions: (state, data : any) => {
        state.modelActions = data;
    }
}
