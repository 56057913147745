/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {TranslateState} from './types';
import {RootState} from '../types';

export const state: TranslateState = {
	message: ''
};

const namespaced: boolean = true;

export const translate: Module<TranslateState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
