/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { ControlTariff } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: ControlTariff = {
	allTarifs: [
		{
			id: 0,
			model_id: 0,
			days: 0,
			description: "",
			price: 0
		},
	],
	models: [
		{
			id:	0,
			model_id: 0,
			days: 0,
			description: 'Galla',
			price: 15

		},
		{
			id:	1,
			model_id: 1,
			days: 1,
			description: 'Alla',
			price: 20
		},
		{
			id:	2,
			model_id: 2,
			days: 2,
			description: 'Valya',
			price: 25
		},
		{
			id:	3,
			model_id: 3,
			days: 3,
			description: 'Olga',
			price: 30
		},
		{
			id:	4,
			model_id: 4,
			days: 4,
			description: 'Vasya',
			price: 35
		},
	],
	selectedModels: [],
	currentViewModel: [],
	amountResult: 0,
	messages: [
		{
			id: 0,
			username: 'Valery',
			message: 'If you wanna be able to chat with me and discover more, just make a free account! xox'
		},
		{
			id: 1,
			username: 'Valery',
			message: 'Make me playfull while you soak up my pussy!'
		},
	],
	controlModels: [
		{
			id: 0,
			name: 'Valya',
			count: 100008
		},
		{
			id: 1,
			name: 'Vika',
			count: 100002
		},
		{
			id: 2,
			name: 'Olga',
			count: 100003
		},
		{
			id: 3,
			name: 'Elena',
			count: 100004
		},
		{
			id: 4,
			name: 'Katya',
			count: 100005
		},
		{
			id: 5,
			name: 'Valya',
			count: 100008
		},
		{
			id: 6,
			name: 'Vika',
			count: 1000010
		},
		{
			id: 7,
			name: 'Olga',
			count: 9999
		},
		{
			id: 8,
			name: 'Elena',
			count: 8888
		},
		{
			id: 9,
			name: 'Katya',
			count: 7777
		},
	],
	allVtcTariff: [
		{
			id: 0,
			model_id: 0,
			days: 28,
			description: '',
			price: 1500
		},
		{
			id: 1,
			model_id: 0,
			days: 1,
			description: '',
			price: 100
		},
	],
	allSvtcTariff: [
		{
			id: 0,
			model_id: 0,
			days: 28,
			description: '',
			price: 1500
		},
		{
			id: 1,
			model_id: 0,
			days: 1,
			description: '',
			price: 100
		},
	],
};

const namespaced: boolean = true;

export const tariff: Module<ControlTariff, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
