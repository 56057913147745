/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { SecretDesire } from '@/store/secretDesire/types';

export const getters: GetterTree<SecretDesire, RootState> = {
	getArrAllModels: (state) => {
		return state.arrAllModels;
	},
	getNumOfModels: (state) => {
		return state.numOfModels;
	},
	getSelectedModels: state => {
		return state.numOfModels;
	}
}
