/* eslint-disable */
import {ActionTree} from "vuex";
import {TranslateState} from "@/store/translate/types";
import {RootState} from "@/store/types";
import api from '@/services/api.service';

export const actions: ActionTree<TranslateState, RootState> = {
	getLanguage: async ({commit}): Promise<any> => {

		const answer = await api.get('/language')
			.then((response) => {
				return response;
			});
		console.log(answer);
	},
	getLanguageSource: async ({commit}): Promise<any> => {
		const answer = await api.get('/language_source')
			.then((response) => {
				return response;
			});
		console.log(answer);
	},
	getLanguageTranslate: async ({commit}): Promise<any> => {
		const answer = await api.get('/language_translate')
			.then((response) => {
				return response;
			});
		console.log(answer);
	},
};
