/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { Support } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: Support = {

};

const namespaced: boolean = true;

export const support: Module<Support, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
