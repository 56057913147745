/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ModelAccountState } from '@/store/modelAccount/types';
import { setSessionStorageService } from '@/services/storage.service'

export const getters: GetterTree<ModelAccountState, RootState> = {
	getFirsLoadState: (state) => {
		return state.firstLoad;
	},
	getRequestStatus: state => {
		return state.isRequestStatus;
	},
	getMessage: state => {
		return state.message;
	},
	getModelAgreement: (state) :boolean => {
		return state.stateAgreement;
	},
	getModelLogin: (state) :string => {
		return state.login;
	},
	getModelEmail: (state) => {
		return state.modelData.email;
	},
	getModelName: (state, name: string) => {
		state.modelData.name = name;
	},
	getModelLastName: (state) => {
		return state.modelData.lastName;
	},
	getModelPhone: (state) => {
		return state.modelData.phone;
	},
	getModelCountry: (state) => {
		return state.modelData.country;
	},
	getModelRegion: (state) => {
		return state.modelData.region;
	},
	getModelCity: (state) => {
		return state.modelData.city;
	},
	getModelAddress: (state) => {
		return state.modelData.address;
	},
	getModelPostcode: (state) => {
		return state.modelData.postcode;
	},
	getModelDCMAProtect: (state) => {
		return state.modelData.dcmaProtect;
	},
	getModelEmailNotification: (state) => {
		return state.modelData.emailNotification;
	},
	getModelSpecialOffers: (state) => {
		return state.modelData.specialOffers;
	},
	getModelDocumentPhoto: (state) => {
		return state.modelData.documentPhoto;
	},
	getModelFacePhoto: (state) => {
		return state.modelData.facePhoto;
	},
	getModelFaceInDocPhoto: (state) => {
		return state.modelData.faceInDocPhoto;
	},
	getModelDocumentAvailable: (state) => {
		return state.modelData.documentAvailable;
	},
	getModelBirthday: (state) => {
		return state.modelData.birthday;
	},
// ======= Model Settings Getters ====

	getModelSettingsUsername: (state) => {
		return state.modelSettings.username;
	},
	getModelSettingsAge: (state) => {
		return state.modelSettings.age;
	},
	getModelSettingsEthnicOrigin: (state) => {
		return state.modelSettings.ethnicOrigin;
	},
	getModelSettingsSexualPreferences: (state) => {
		return state.modelSettings.sexualPreferences;
	},
	getModelSettingsNativeLanguage: (state) => {
		return state.modelSettings.nativeLanguage;
	},
	getModelSettingsMoreLanguage: (state) => {
		return state.modelSettings.moreLanguage;
	},
	getModelSettingsCountry: (state) => {
		return state.modelSettings.country;
	},
	getModelSettingsCity: (state) => {
		return state.modelSettings.city;
	},
	getModelSettingsGrowth: (state) => {
		return state.modelSettings.growth;
	},
	getModelSettingsBodyType: (state) => {
		return state.modelSettings.bodyType;
	},
	getModelSettingsEyeColor: (state) => {
		return state.modelSettings.eyeColor;
	},
	getModelSettingsButtSize: (state) => {
		return state.modelSettings.buttSize;
	},
	getModelSettingsWeight: (state) => {
		return state.modelSettings.weight;
	},
	getModelSettingsHairColor: (state) => {
		return state.modelSettings.hairColor;
	},
	getModelSettingsBreastSize: (state) => {
		return state.modelSettings.breastSize;
	},
	getModelSettingsIntimateHaircut: (state) => {
		return state.modelSettings.intimateHaircut;
	},
	getModelSettingsAbout: (state) => {
		return state.modelSettings.about;
	},
	getModelSettingsImage: (state) => {
		return state.modelSettings.image;
	},
	getModelSettingsTags: (state) => {
		return state.modelSettings.tags;
	},
	getDataConfidentiality: (state) => {
		return state.dataConfidentiality;
	},
	getModelsPhoto: (state) => {
		return state.modelsPhotos;
	},
    getFormValues: (state) => {
        return state.formValues;
    },
    getModelData: (state) => {
        return state.data;
    },
    getCountriesData: (state) => {
        return state.countries;
    },
    getRegionsData: (state) => {
        return state.regions;
    },
    getCitiesData: (state) => {
        return state.cities;
    },
    allLangs: (state) => {
        return state.allLangs;
    },
    modelInfo: (state) => {
        return state.modelInfo;
    },
    modelTags: (state) => {
        return state.modelTags;
    },
    shopCategories: state => {
        return state.shopCategories;
    },
    modelProducts: state => {
        return state.modelProducts;
    },
}
