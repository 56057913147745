/* eslint-disable */
import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {getLocalStorageService, getSessionStorageService, setLocalStorageService} from '@/services/storage.service'

var urlArr = window.location.pathname.split("/").filter(n => n);
//Приоритет - язык указанный в url
var urlLang = urlArr[0];
if (urlLang && (urlLang == "ru" || urlLang == "en"))
    setLocalStorageService("language", urlLang);
else
    urlLang = getLocalStorageService("language") || "en";

var selectedLang = '/'+urlLang;

const pages = [
	{
		name: 'login',
		meta: {
			guest: true
		}
	},
	{
		name: '/',
		redirect: 'main',
		meta: {
			guest: true
		}
	},
	{
		name: 'main',
		meta: {
			guest: true
		}
	},
	{
		name: 'create-account',
		meta: {
			guest: true
		}
	},
	{
		name: 'change-password'
	},
	{
		name: 'activation-promo-code'
	},
	{
		name: 'balance-replenishment'
	},
	{
		name: 'balance-replenishment-by-card'
	},
	{
		name: 'balance-replenishment-by-crypto'
	},
	{
		name: 'balance-replenishment-by-wallet'
	},
	{
		name: 'bill-formed'
	},
	{
		name: 'payment-method'
	},
	{
		name: 'personal-account'
	},
	{
		name: 'reset-password'
	},
	{
		name: 'user-account'
	},
	{
		name: 'user-account-favourites',
		componentPath: 'user-account/favourites/user-account-favourites.page.vue'
	},
	{
		name: 'info'
	},
	{
		name: 'notifications',
	},
	{
		name: 'messages'
	},
	{
		name: 'messages-reply'
	},
	{
		name: 'cooperation-girls',
		componentPath: 'cooperation/girls/cooperation-girls.page.vue'
	},
	{
		name: 'cooperation-partners',
		componentPath: 'cooperation/partners/cooperation-partners.page.vue'
	},
	{
		name: 'become-guest-terms-and-rules',
		componentPath: 'become-guest/terms-and-rules/become-guest-terms-and-rules.page.vue'
	},
	{
		name: 'become-guest-request',
		componentPath: 'become-guest/request/become-guest-request.page.vue'
	},
    {
        name: 'shop/:modelname',
        componentPath: 'shop/model-shop/model-shop.page.vue'
    },
	{
		name: 'shop-model-choice',
		componentPath: 'shop/model-choice/model-choice.page.vue'
	},
	{
		name: 'shop-model-shop',
		componentPath: 'shop/model-shop/model-shop.page.vue'
	},
	{
		name: 'shop-delivery',
		componentPath: 'shop/delivery/shop-delivery.page.vue'
	},
	{
		name: 'shop-order',
		componentPath: 'shop/order/order.page.vue'
	},
	{
		name: 'shop-ordering-step2',
		componentPath: 'shop/ordering-step2/shop-ordering-step2.page.vue'
	},
	{
		name: 'shop-ordering-step3',
		componentPath: 'shop/ordering-step3/shop-ordering-step3.page.vue'
	},
	{
		name: 'shop-ordering-step3-payment',
		componentPath: 'shop/ordering-step3-payment/shop-ordering-step3-payment.page.vue'
	},
	{
		name: 'shop-basket',
		componentPath: 'shop/basket/shop-basket.page.vue'
	},
	{
		name: 'secret-desire-request',
		componentPath: 'secret-desire/request/secret-desire-request.page.vue'
	},
	{
		name: 'secret-desire-model-choice',
		componentPath: 'secret-desire/model-choice/secret-desire-model-choice.page.vue'
	},
	{
		name: 'his-own-director-request',
		componentPath: 'his-own-director/request/his-own-director-request.page.vue'
	},
	{
		name: 'his-own-director-model-choice',
		componentPath: 'his-own-director/model-choice/his-own-director-model-choice.page.vue'
	},
	{
		name: 'his-own-director-video-greetings-request',
		componentPath: 'his-own-director/video-greetings-request/his-own-director-video-greetings-request.page.vue'
	},
	{
		name: 'his-own-director-video-greetings-model-choice',
		componentPath: 'his-own-director/video-greetings-model-choice/his-own-director-video-greetings-model-choice.page.vue'
	},
	{
		name: 'sex-master-classes-model-choice',
		componentPath: 'sex-master-classes/model-choice/sex-master-classes-model-choice.page.vue'
	},
	{
		name: 'sex-master-classes-video-choice',
		componentPath: 'sex-master-classes/video-choice/sex-master-classes-video-choice.page.vue'
	},
	{
		name: 'sex-master-classes-video-chosen',
		componentPath: 'sex-master-classes/video-chosen/sex-master-classes-video-chosen.page.vue'
	},
	{
		name: 'sex-master-classes-video-viewing',
		componentPath: 'sex-master-classes/video-viewing/sex-master-classes-video-viewing.page.vue'
	},
	{
		name: 'video-stories-model-choice',
		componentPath: 'video-stories/model-choice/video-stories-model-choice.page.vue'
	},
	{
		name: 'video-stories-video-choice',
		componentPath: 'video-stories/video-choice/video-stories-video-choice.page.vue'
	},
	{
		name: 'video-stories-video-chosen',
		componentPath: 'video-stories/video-chosen/video-stories-video-chosen.page.vue'
	},
	{
		name: 'video-stories-video-viewing',
		componentPath: 'video-stories/video-viewing/video-stories-video-viewing.page.vue'
	},
	{
		name: 'asmr-model-choice',
		componentPath: 'asmr/model-choice/asmr-model-choice.page.vue'
	},
	{
		name: 'asmr-video-choice',
		componentPath: 'asmr/video-choice/asmr-video-choice.page.vue'
	},
	{
		name: 'asmr-video-chosen',
		componentPath: 'asmr/video-chosen/asmr-video-chosen.page.vue'
	},
	{
		name: 'asmr-video-viewing',
		componentPath: 'asmr/video-viewing/asmr-video-viewing.page.vue'
	},
	{
		name: 'fetish-categories',
		componentPath: 'fetish/categories/fetish-categories.page.vue'
	},
	{
		name: 'fetish-model-choice',
		componentPath: 'fetish/model-choice/fetish-model-choice.page.vue'
	},
	{
		name: 'fetish-video-choice',
		componentPath: 'fetish/video-choice/fetish-video-choice.page.vue'
	},
	{
		name: 'fetish-video-chosen',
		componentPath: 'fetish/video-chosen/fetish-video-chosen.page.vue'
	},
	{
		name: 'fetish-video-viewing',
		componentPath: 'fetish/video-viewing/fetish-video-viewing.page.vue'
	},
	{
		name: 'bdsm-show-viewing',
		componentPath: 'bdsm-show/viewing/bdsm-show-viewing.page.vue'
	},
	{
		name: 'bdsm-show-viewing-not-logined',
		componentPath: 'bdsm-show/viewing-not-logined/bdsm-show-viewing-not-logined.page.vue'
	},
	{
		name: 'bdsm-show-viewing-no-money',
		componentPath: 'bdsm-show/viewing-no-money/bdsm-show-viewing-no-money.page.vue'
	},
	{
		name: 'bdsm-show-archive',
		componentPath: 'bdsm-show/archive/bdsm-show-archive.page.vue'
	},
	{
		name: 'control-tariff-model-tariff-choice',
		componentPath: 'control-tariff/model-tariff-choice/control-tariff-model-tariff-choice.page.vue'
	},
	{
		name: 'control-tariff-room-chat',
		componentPath: 'control-tariff/room-chat/control-tariff-room-chat.page.vue'
	},
	{
		name: 'vtc-tariff-choice',
		componentPath: 'vtc-tariff/tariff-choice/vtc-tariff-choice.page.vue'
	},
	{
		name: 'vtc-tariff-room-chat',
		componentPath: 'vtc-tariff/room-chat/vtc-tariff-room-chat.page.vue'
	},
	{
		name: 'svtc-tariff-choice',
		componentPath: 'svtc-tariff/tariff-choice/svtc-tariff-choice.page.vue'
	},
	{
		name: 'svtc-tariff-room-chat',
		componentPath: 'svtc-tariff/room-chat/svtc-tariff-room-chat.page.vue'
	},
	{
		name: 'sex-reality-show-viewing',
		componentPath: 'sex-reality-show/viewing/sex-reality-show-viewing.page.vue'
	},
	{
		name: 'sex-reality-show-viewing-not-logined',
		componentPath: 'sex-reality-show/viewing-not-logined/sex-reality-show-viewing-not-logined.page.vue'
	},
	{
		name: 'sex-reality-show-viewing-no-money',
		componentPath: 'sex-reality-show/viewing-no-money/sex-reality-show-viewing-no-money.page.vue'
	},
	{
		name: 'sex-reality-show-archive',
		componentPath: 'sex-reality-show/archive/sex-reality-show-archive.page.vue'
	},
	{
		name: 'p&p-web-models-model-choice',
		componentPath: 'p&p-web-models/model-choice/p&p-web-models-model-choice.page.vue'
	},
	{
		name: 'p&p-web-models-view/:username',
		componentPath: 'p&p-web-models/viewing-chat/p&p-web-models-view.page.vue'
	},
	{
		name: 'p&p-web-models-viewing-peep',
		componentPath: 'p&p-web-models/viewing-peep/p&p-web-models-viewing-peep.page.vue'
	},
	{
		name: 'p&p-web-models-model-choice',
		componentPath: 'p&p-web-models/model-choice/p&p-web-models-model-choice.page.vue'
	},
	{
		name: 'model-account-registration',
		componentPath: 'model-account/registration/model-account-registration.page.vue'
	},
	{
		name: 'model-account-email-confirmation',
		componentPath: 'model-account/email-confirmation/model-account-email-confirmation.page.vue'
	},
	{
		name: 'model-account-contact-information',
		componentPath: 'model-account/contact-information/model-account-contact-information.page.vue'
	},
	{
		name: 'model-account-profile-information',
		componentPath: 'model-account/profile-information/model-account-profile-information.page.vue'
	},
	{
		name: 'model-account-agreement-pp',
		componentPath: 'model-account/agreement-pp/model-account-agreement-pp.page.vue'
	},
	{
		name: 'model-account-payment-information',
		componentPath: 'model-account/payment-information/model-account-payment-information.page.vue'
	},
	{
		name: 'model-account-my-account-statistics',
		componentPath: 'model-account/my-account-statistics/model-account-my-account-statistics.page.vue'
	},
	{
		name: 'model-account-my-account-payout-history',
		componentPath: 'model-account/my-account-payout-history/model-account-my-account-payout-history.page.vue'
	},
	{
		name: 'model-account-my-account',
		componentPath: 'model-account/my-account/model-account-my-account.page.vue'
	},
	{
		name: 'model-account-my-account-notifications',
		componentPath: 'model-account/my-account-notifications/model-account-my-account-notifications.page.vue'
	},
	{
		name: 'model-account-my-account-dmca',
		componentPath: 'model-account/my-account-dmca/model-account-my-account-dmca.page.vue'
	},
	{
		name: 'model-account-my-account-order-payout',
		componentPath: 'model-account/my-account-order-payout/model-account-my-account-order-payout.page.vue'
	},
	{
		name: 'model-account-my-account-confidentiality',
		componentPath: 'model-account/my-account-confidentiality/model-account-my-account-confidentiality.page.vue'
	},
	{
		name: 'model-account-my-account-agreement',
		componentPath: 'model-account/my-account-agreement/model-account-my-account-agreement.page.vue'
	},
	{
		name: 'model-account-my-account-main-info',
		componentPath: 'model-account/my-account-main-info/model-account-my-account-main-info.page.vue'
	},
	{
		name: 'model-account-my-account-figure-parameters',
		componentPath: 'model-account/my-account-figure-parameters/model-account-my-account-figure-parameters.page.vue'
	},
	{
		name: 'model-account-my-account-about-me',
		componentPath: 'model-account/my-account-about-me/model-account-my-account-about-me.page.vue'
	},
	{
		name: 'model-account-my-account-tags',
		componentPath: 'model-account/my-account-tags/model-account-my-account-tags.page.vue'
	},
	{
		name: 'model-account-my-account-add-photo',
		componentPath: 'model-account/my-account-add-photo/model-account-my-account-add-photo.page.vue'
	},
	{
		name: 'model-account-my-account-add-video',
		componentPath: 'model-account/my-account-add-video/model-account-my-account-add-video.page.vue'
	},
	{
		name: 'model-account-my-account-add-photo-added',
		componentPath: 'model-account/my-account-add-photo-added/model-account-my-account-add-photo-added.page.vue'
	},
	{
		name: 'model-account-my-account-add-video-added',
		componentPath: 'model-account/my-account-add-video-added/model-account-my-account-add-video-added.page.vue'
	},
	{
		name: 'support-service',
		componentPath: 'footer-pages/support-service/support-service.page.vue'
	},
	{
		name: 'how-make-order',
		componentPath: 'footer-pages/how-make-order/how-make-order.page.vue'
	},
	{
		name: 'worlds-web-models-viewing-chat',
		componentPath: 'worlds-web-models/viewing-chat/worlds-web-models-viewing-chat.page.vue'
	},
	{
		name: 'worlds-web-models-viewing-services-list',
		componentPath: 'worlds-web-models/viewing-services-list/worlds-web-models-viewing-services-list.page.vue'
	},
	{
		name: 'worlds-web-models-viewing-peep',
		componentPath: 'worlds-web-models/viewing-peep/worlds-web-models-viewing-peep.page.vue'
	},
	{
		name: 'worlds-web-models-viewing-not-logined',
		componentPath: 'worlds-web-models/viewing-not-logined/worlds-web-models-viewing-not-logined.page.vue'
	},
	{
		name: 'worlds-web-models-photos',
		componentPath: 'worlds-web-models/photos/worlds-web-models-photos.page.vue'
	},
	{
		name: 'worlds-web-models-photos-big-size',
		componentPath: 'worlds-web-models/photos-big-size/worlds-web-models-photos-big-size.page.vue'
	},
	{
		name: 'worlds-web-models-videos',
		componentPath: 'worlds-web-models/videos/worlds-web-models-videos.page.vue'
	},
	{
		name: 'worlds-web-models-videos-big-size',
		componentPath: 'worlds-web-models/videos-big-size/worlds-web-models-videos-big-size.page.vue'
	},
	{
		name: 'worlds-web-models-model-choice',
		componentPath: 'worlds-web-models/model-choice/worlds-web-models-model-choice.page.vue'
	},
	{
		name: 'model-at-work-in-mode-webcam',
		componentPath: 'model-at-work/in-mode-webcam/model-at-work-in-mode-webcam.page.vue'
	},
	{
		name: 'model-at-work-in-mode-webcam-in-private',
		componentPath: 'model-at-work/in-mode-webcam-in-private/model-at-work-in-mode-webcam-in-private.page.vue'
	},
	{
		name: 'model-at-work-in-mode-live',
		componentPath: 'model-at-work/in-mode-live/model-at-work-in-mode-live.page.vue'
	},
    {
        name: 'my-shop',
        componentPath: 'model-account/my-shop/my-shop.page.vue'
    },
    {
        name: 'my-orders',
        componentPath: 'model-account/my-orders/my-orders.page.vue'
    },
	{
		name: 'privacy-policy',
		componentPath: 'documents/privacy-policy/privacy-policy.page.vue'
	},
	{
		name: 'copyright',
		componentPath: 'documents/copyright/copyright.page.vue'
	},
	{
		name: 'offer',
		componentPath: 'documents/offer/offer.page.vue'
	},
	{
		name: 'parent-control',
		componentPath: 'documents/parent-control/parent-control.page.vue'
	},
	{
		name: 'aml',
		componentPath: 'documents/aml/aml.page.vue'
	},
];

const routes: Array<RouteRecordRaw> = [
	...pages.map(page => {
		return {
			path: '/' + page.name,
			redirect: page.name === '/' ? selectedLang + '/main' : selectedLang + '/' + page.name,
		}
	}),
	...pages.map(page => {
		return {
			path: '/:lngId/' + page.name,
			name: page.name,
			component: page.componentPath ? () => import('../components/pages/' + page.componentPath) : () => import('../components/pages/' + page.name + '/' + page.name + '.page.vue'),
			meta: page.meta
		}
	})
];

const routing = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

routing.beforeEach((to, from, next) => {
	const userIsLogin = getSessionStorageService('isLogin') || false;

	//console.log("Router: ", to, from, next);

    if (!getLocalStorageService('confirmAge18'))
	{
        if (to.name != "main") {
            if (confirm("You are over 18?"))
			{
                setLocalStorageService('confirmAge18', true);
			}
			else
            {
                next('/main');
                return;
            }
        }
	}

	// const protectedRotes = ['login', 'create-account'];
	if (!userIsLogin && to.name === 'login') {
		next();
	} else if(userIsLogin && to.name === 'login'){
		next('/main');
	} else {
		next();
	}
});

export default routing;
