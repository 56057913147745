/* eslint-disable */
import {ActionTree} from "vuex";
import {PaymentState} from "@/store/payments/types";
import {RootState} from "@/store/types";
import apiUserData from '@/services/getUserData.service';

export const actions: ActionTree<PaymentState, RootState> = {
	fetchPay: async ({commit}, pay: Object): Promise<any> => {
		const answer = await apiUserData.post('', pay)
			.then(response => {
				return response
			});
		console.log(answer);
	},
	fetchCardData: async ({commit}, pay: Object): Promise<any> => {
		const answer = await apiUserData.post('', pay)
			.then(response => {
				return response
			});
		console.log(answer);
	},
};
