/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {PaymentState} from './types';
import {RootState} from '../types';

export const state: PaymentState = {
	message: '',
	accountMoney: '0'
};

const namespaced: boolean = true;

export const payments: Module<PaymentState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
