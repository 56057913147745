/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { WorldsWebModels } from '@/store/worldsWebModels/types';

export const mutations: MutationTree<WorldsWebModels> = {
    setArrWebModels: (state, models: Array<any>) => {
		state.arrWebModels = models.slice();
	},
    setPaginationInfo: (state, data : any) => {
        state.pagination = data;
    },
	setMessageToChat: (state, array) => {
		state.messages.slice(array);
	},
	setCardModelInfo: (state, card) => {
		state.cardModelInfo = {
			username: card.username,
			age: card.age,
			sexualPreferences: card.sexualPreferences,
			breastSize: card.breastSize,
			ethnicOrigin: card.ethnicOrigin,
			country: card.country,
			language: card.language,
			about: card.about
		}
	},
	setModelSevicesCard: (state, card) => {
		state.modelServiceCard.slice(card);
	},
	setItemPhoto: (state, item: number) => {
		state.itemPhoto = item;
	},
	setItemVideo: (state, item: number) => {
		state.itemVideo = item;
	},
}
