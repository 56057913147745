/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { HisOwnDirector } from '@/store/hisOwnDirector/types'

export const actions: ActionTree<HisOwnDirector, RootState> = {
	getAllModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/own-director/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		if(answer.data) {
			commit('setListModels', answer.data)
		}
	},
	storeRequestHisOnDirector: async ({commit}, requestData: Object): Promise<any> => {
		const answer = await apiUserData.post('/own-direcor/add-request', requestData)
			.then((response) => {
				console.log(response);
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log(answer);
		}
	},
	storeSelectedModel: ({commit}, models: Array<any>) => {
		commit('setSelectedModels', models);
	},

	fetchSecretDesireModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/secret-wish/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		if(answer.data) {
			commit('setListModels', answer.data)
		}
	},
};
