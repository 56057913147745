/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { SecretDesire } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: SecretDesire = {
	arrAllModels: [
		{
			id: 0,
			login: 'Alla'
		},
		{
			id: 1,
			login: 'Galla'
		},
		{
			id: 2,
			login: 'Vallya'
		},
		{
			id: 3,
			login: 'Vika'
		},
		{
			id: 4,
			login: 'Lika'
		},
		{
			id: 5,
			login: 'Motya'
		},
		{
			id: 6,
			login: 'Lilya'
		},
		{
			id: 7,
			login: 'Sveta'
		},
		{
			id: 8,
			login: 'Katya'
		},
	],
	selectedModels: JSON.parse(<string>getSessionStorageService('selectedModels')) || [],
	numOfModels: parseInt(<string>getSessionStorageService('numOfModels')) || 0,
};

const namespaced: boolean = true;

export const secretDesire: Module<SecretDesire, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
