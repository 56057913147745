/*eslint-disable*/
export const setSessionStorageService = (keyName: string, value: any) => {
	return sessionStorage.setItem(`${keyName}`, value)
}
export const getSessionStorageService = (keyName: string) => {
	return sessionStorage.getItem(`${keyName}`)
}
export const setLocalStorageService = (keyName: string, value: any) => {
	return localStorage.setItem(`${keyName}`, value)
}
export const getLocalStorageService = (keyName: string) => {
	return localStorage.getItem(`${keyName}`)
}
export const clearSessionStorageService = () => {
	return sessionStorage.clear();
}
export const clearLocalStorageService = () => {
	return localStorage.clear();
}

