/* eslint-disable */
import { MutationTree } from 'vuex';
import { Shop } from '@/store/shop/types'

export const mutations: MutationTree<Shop> = {
    setShopModels: (state, data) => {
        state.shopModels = data;
    },

	
	setArrModel: (state, models: Array<any>) => {
		state.arrModel = models.slice();
	},
	setModelProducts: (state, product) => {
		state.modelProducts = product.slice();
	},
	setModelChosen: (state, model: Object) => {
		console.log(model);
		state.modelChosen = model;
		console.log(state.modelChosen)
	},
	addProductToBasket: (state, currentProduct: any) => {
		state.basket?.push(currentProduct);
	},
	setCurrentProduct: (state, product) => {
		state.currentViewProduct = product
	},
	setBasket: (state, product: Array<any>) => {
		state.basket?.concat(product);
	},
	setCountProductMinus: (state, item) => {
		if(state.basket !== undefined)
		state.basket[item].count--;
	},
	setCountProductPlus: (state, item) => {
		if(state.basket !== undefined)
		state.basket[item].count++;
	},
	setAmount: (state, amount: number) => {
		state.amount = amount;
	},
	setRemoveItemBasket: (state, id: number) => {
		state.basket = state.basket?.filter(product => product.id !== id);
	},
	setPaymentOrder: (state, order) => {
		state.paymentOrder = {
			country: order.country,
			email: order.email,
			name: order.name,
			lastname: order.lastname,
			city: order.city,
			address: order.address,
			region: order.region,
			postcode: order.postcode,
			delivery: order.delivery
		}
	},
	setDeliveryCountry: (state, country) => {
		state.country = country.slice();
	},
    setDeliveryCountries: (state, data : any) => {
        state.deliveryCountries = data;
    }
}
