/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { HisOwnDirector } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: HisOwnDirector = {
	arrAllModels: [1,2,3,4,5,6,7,8,9],
	selectedModels: JSON.parse(<string>getSessionStorageService('selectedModels')) || [],
	numOfModels: parseInt(<string>getSessionStorageService('numOfModels')) || 0,
};

const namespaced: boolean = true;

export const hisOwnDirector: Module<HisOwnDirector, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
