/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { VideoStories } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: VideoStories = {
	modelList: [
		{
			id: 0,
			name: 'Valya'
		},
		{
			id: 1,
			name: 'Vika'
		},
		{
			id: 2,
			name: 'Olga'
		},
		{
			id: 3,
			name: 'Elena'
		},
		{
			id: 4,
			name: 'Katya'
		},
	],
	modelVideoList: [
		{
			id: 1,
			name: 'Video1',
			price: 1
		},
		{
			id: 2,
			name: 'lVideo',
			price: 1
		},
		{
			id: 3,
			name: 'Video3',
			price: 1
		},
		{
			id: 4,
			name: 'Video4',
			price: 1
		},
		{
			id: 5,
			name: 'Video5',
			price: 1
		},
		{
			id: 6,
			name: 'Video6',
			price: 1
		},
		{
			id: 7,
			name: 'Video7',
			price: 1
		},
		{
			id: 8,
			name: 'Video8',
			price: 1
		},
	],
	chosenModels: {
		id: 999,
		name: 'Valery'
	},
	chosenVideo: {
		id: 0,
		name: 'Video1',
		price: 1
	},
	myCount: 1
};

const namespaced: boolean = true;

export const videoStories: Module<VideoStories, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
