/* eslint-disable */
import { MutationTree } from 'vuex';
import { CustomerState } from '@/store/customer/types';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';

export const mutations: MutationTree<CustomerState> = {
	saveToken: (state, token: string) => {
		if (state.isRememberPassword) {
			setLocalStorageService('token', token);
			setSessionStorageService('token', token);

		} else {
			setSessionStorageService('token', token);
		}
	},
	setRole: (state, role: number) => {
		state.role = role;
		localStorage.setItem('role', JSON.stringify(role));
		setSessionStorageService('role', role);
	},
	setError: (state, message: Array<any>) => {
		state.messages = message.slice();
		// console.log(state.messages);
	},
	setRememberPassword: (state, rememberPassword: boolean) => {
		const rememberPasswordJson: string = JSON.stringify(rememberPassword);
		if (rememberPassword) setLocalStorageService('rememberPassword', rememberPasswordJson);
		state.isRememberPassword = rememberPassword;
	},
	stateAgreement: (state, isAgreement: boolean) => {
		state.isAgreement = isAgreement;
	},
	setLanguage: (state, language: string) => {
		state.language = language;
		setLocalStorageService('language', language);
	},
	setIsLogin: (state, loginState: boolean) => {
		const isLoginState: string = JSON.stringify(loginState)
		if(loginState) setSessionStorageService('isLogin', isLoginState);
		state.isLogin = loginState;
	},
	setLogin: (state, login: string) => {
		state.login = login;
		if(state.isLogin){
			setLocalStorageService('login', login);
		} else {
			setSessionStorageService('login', login)
		}
	},
	setEmailChange: (state, isEmailChanged: boolean) => {
		state.isEmailChanged = isEmailChanged;
	},
	setPasswordChange: (state, isPasswordChanged: boolean) => {
		state.isPasswordChanged = isPasswordChanged;
	},
	setChangeLogin: (state, isLoginChanged: boolean) => {
		state.isLoginChanged = isLoginChanged;
	},
	setBalanceReplenishment: (state, balance: string) => {
		state.balanceReplenishment = balance;
	},
	setTypeCrypto: (state, type: string) => {
		state.typeCrypto = type;
	},
	setTypeWallet: (state, type: string) => {
		state.typeWallet = type;
	},
	setRequestStatus: (state, status: boolean) => {
		state.requestStatus = status;
	},
	setTariff: (state, tariff: Object) => {
		// console.log(tariff)
		state.tariff = tariff;
	},
	setAccountMoney: (state, money:number) => {
		state.accountMoney = money;
		console.log(state.accountMoney);
	},
	setFirstLoad: (state, firstLoad: string) => {
		setLocalStorageService('firstLoad', firstLoad);
	},
	setClearMessages: (state) => {
		state.messages = [];
	},
	setIsRecoveryPassword: (state, value: boolean) => {
		state.isRecoveryPassword = value;
		localStorage.setItem('isRecoveryPassword', JSON.stringify(value));
	},
	setFetchedUserData: (state, value: boolean) => {
		state.isFetchedUserData = value;
	},
    setUserData: (state, value: any) => {
        state.userData = value;
    },
    setEmail: (state, value: Object) => {
        state.email = value;
    },
    setUserSettings: (state, data: any) => {
        state.userSettings = data;
    },
    setBasket: (state, data: any) => {
        state.basket = data;
    },
}
