/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { SexRealityShow } from '@/store/sexRealityShow/types';

export const mutations: MutationTree<SexRealityShow> = {
	setRealityShowModels: (state, models: Array<any>) => {
		state.realityShowModels = models.slice();
	},
	setArchivesVideo: (state, videos: Array<any>) => {
		state.archivesVideo = videos.slice();
	}
}
