/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { ControlTariff } from '@/store/tariff/types'

export const actions: ActionTree<ControlTariff, RootState> = {
	fetchTariff: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/control-tarif/get-tarif/?type=control')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(answer.status) {
			commit('setTariff', data);
		}
		if(!answer.status) {
			console.log(errors);
		}
	},
	fetchModelsTariff: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/control-tarif/get-tarif-models/?type="Control"')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			commit('setModelsTariff', data);
		}
	},
	storeTariff: async ({commit}, tarif: Array<any>): Promise<any> => {
		const answer = await apiUserData.post('/control-tarif/add-tarif', tarif)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			console.log('Great!');
		}
	},
	storeCameraPosition: async ({commit}, position: string): Promise<any> => {
		const answer = await apiUserData.post('', position)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		// const {data, errors} = answer;
		// if(answer.status) {
		//
		// }
		// if(errors) console.log(errors);
	},
	storeChatMessage: async ({commit}, message: Object): Promise<any> => {
		const answer = await apiUserData.post('', message)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer.data;
		if(data) commit('setMessageToChat', data)
		if(errors) console.log(errors);
	},
	fetchChatMessage: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer.data;
		if(data) commit('setMessageToChat', data)
		if(errors) console.log(errors);
	},
	storeDonate: async ({commit}, donate: Object): Promise<any> => {
		const answer = await apiUserData.post('', donate)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer.data;
		// if(data) commit('setMessageToChat', data)
		if(errors) console.log(errors);
	},
	unlockSuccess: async ({commit}, donate: Object): Promise<any> => {
		const answer = await apiUserData.post('', donate)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer.data;
		// if(data) commit('setMessageToChat', data)
		if(errors) console.log(errors);
	},
	fetchVtcTariff: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/control-tarif/get-tarif/?type=vtc')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			commit('setVtcTariff', data);
		}
	},
	storeVtcTariff: async ({commit}, tarif: Array<any>): Promise<any> => {
		const answer = await apiUserData.post('/control-tarif/add-vtc-tarif', tarif)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			console.log('Great!');
		}
	},
	fetchSvtcTariff: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/control-tarif/get-tarif/?type=svtc')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			commit('setSvtcTariff', data);
		}
		if(errors) {
			console.log(errors);
		}
	},
	storeSvtcTariff: async ({commit}, tarif: Array<any>): Promise<any> => {
		const answer = await apiUserData.post('/control-tarif/add-svtc-tarif', tarif)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			console.log('Great!');
		}
	},
};
