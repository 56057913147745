/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { SexRealityShow } from '@/store/sexRealityShow/types';

export const getters: GetterTree<SexRealityShow, RootState> = {
	getRealityShowModels: state => {
		return state.realityShowModels.sort((a, b) => b.count - a.count);
	},
	getRealityShowLeader: state => {
		return state.realityShowModels.sort((a, b) => b.count - a.count).shift();
	},
	getArchivesVideo: state => {
		return state.archivesVideo;
	}
}
