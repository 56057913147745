/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import apiUserData  from '@/services/getUserData.service';
import { WorldsWebModels } from '@/store/worldsWebModels/types';
// import api from '@/services/api.service';
import router from '@/route/routing';

export const actions: ActionTree<WorldsWebModels, RootState> = {
	storeFilterOptions: async ({commit}, filter: Object): Promise<any> => {
		const answer = await apiUserData.post('', filter)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
	},
	getAllWebModels:  async ({commit}, query : string): Promise<any> => {
		const answer = await apiUserData.get('/model/get-all'+query)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		const {data, errors} = answer;
		if (answer.status) {
            commit('setArrWebModels', data.items);
            commit('setPaginationInfo', { page : data.page, totalPages : data.totalPages, totalItems : data.totalItems });
		}

		if (!answer.status || errors.length > 0) console.log(errors);

	},
	getChatMessages:  async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		const {data, errors} = answer.data;
		if(data) commit('setMessageToChat', data)
		if(errors) console.log(errors);
	},
	storeChatMessage: async ({commit}, message: Object): Promise<any> => {
		const answer = await apiUserData.post('', message)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer.data;
		if(data) commit('setMessageToChat', data)
		if(errors) console.log(errors);
	},
	storeModelTodo: async ({commit}, todo: Object): Promise<any> => {
		console.log(todo);
		const answer = await apiUserData.post('', todo)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		// const {data, errors} = answer.data;
		// if(data) commit('setMessageToChat', data)
		// if(errors) console.log(errors);
	},
	getCardModelInfo:  async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/world-web/get-model/?name=Adriana')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		// const {data, errors} = answer.data;
		// if(data) commit('setCardModelInfo', data)
		// if(errors) console.log(errors);
	},
	getCardModelServices:  async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/world-web/get-services')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		// const {data, errors} = answer.data;
		// if(data) commit('setCardModelInfo', data)
		// if(errors) console.log(errors);
	},
	storeServicesTodo: async ({commit}, todo: Object): Promise<any> => {
		const answer = await apiUserData.post('', todo)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		// const {data, errors} = answer.data;
		// if(errors) console.log(errors);
	},
	getModelSevicesCard:  async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/world-web/get-services')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		// const {data, errors} = answer.data;
		// if(data) commit('setModelSevicesCard', data)
		// if(errors) console.log(errors);
	},
	storeModelViewingPeep: async ({commit}, peep: Object): Promise<any> => {
		const answer = await apiUserData.post('', peep)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		if (!answer.data.errors){
			router.push({ name: 'worlds-web-models-viewing-chat.page' }).then(r => r)
		}
		// const {data, errors} = answer.data;
		// if(data) commit('setMessageToChat', data)
		// if(errors) console.log(errors);
	},
	storeModelViewingPerMinOrAll: async ({commit}, value: Object): Promise<any> => {
		const answer = await apiUserData.post('', value)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		// const {data, errors} = answer.data;
		// if(data) commit('setMessageToChat', data)
		// if(errors) console.log(errors);
	},
};
