/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { ASMRModelChange } from '@/store/asmr/types';

export const getters: GetterTree<ASMRModelChange, RootState> = {
	getModelsAsmr: state => {
		return state.arrModels;
	},
	getModelItem: state => {
		return state.modelItem;
	},
	getArrVideo: state => {
		return state.arrVideo;
	},
	getPriceVideo: state => {
		return state.priceVideo;
	},
}
