/* eslint-disable */
import {defineComponent} from 'vue';
import Header from '@/components/organisms/header/header.vue';

export default defineComponent({
	name: 'App',
	components: {
		Header
	}
})
