/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { BDSM } from '@/store/bdsm/types';

export const getters: GetterTree<BDSM, RootState> = {
	getTimeLeft: state => {
		return state.timeLeft;
	},
	getBDSMVideo: state => {
		return state.arrVideo;
	}
}
