/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import apiUserData from '@/services/getUserData.service';
import api from '@/services/api.service';
import { ModelAccountState } from '@/store/modelAccount/types';
import router from '@/route/routing';
import {setSessionStorageService, setLocalStorageService} from "@/services/storage.service";
// import { ModelContacts } from '@/components/pages/model-account/contact-information/model-account-contact-information.interface'

export const actions: ActionTree<ModelAccountState, RootState> = {
	createModelAccount: async ({commit}, credentials: Object): Promise<any> => {
		const answer = await api.post('/model/register', credentials)
			.then(response => {
				return response
			});
		if(answer === undefined) {
			commit('setError', ['Something went wrong...']);
		}
		const {data, errors} = answer.data;

		if(errors.length > 0) {
			// console.log(errors);
			commit('setRequestStatus', false);
			commit('setErrorMessage', errors);
		}

		if(data.length !== 0 && answer.status === 200) {
            commit('setLogin', data.login);
			commit('setFirstLoad', 'true');
			commit('saveToken', data.token);
			commit('setIsLogin', true);
            setLocalStorageService('role', 4);
			router.push({name: 'model-account-contact-information'}).then(r => r);
		}
	},
	setContactInformation: async ({commit}, credentials: Object): Promise<any> => {
		var answer = await apiUserData.post('/model/store-model-personal', credentials).then(response => {
			return response;
		});

        Promise.resolve(3);
	},
	setProfileInformation: async ({commit}, credentials: Object): Promise<any> => {
		console.log(credentials);
		const answer = await apiUserData.post('/model-settings/store', credentials)
			.then(response => {
				return response
			});
		// if(answer === undefined) {
		// 	console.log(answer);
		// }
		console.log(answer);
		// const {data, errors} = answer.data;
		// if(errors) {
		// 	console.log(errors);
		// }
		// if(data) {
		// 	// commit()
		 	router.push({name: 'model-account-payment-information'}).then(r => r);
		// }


	},
	updateProfileInformation: async ({commit}, credentials: Object): Promise<any> => {
		console.log(credentials);
		const answer = await apiUserData.put('/model-settings/update', credentials)
			.then(response => {
				return response
			});
		if(answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		const {data, errors} = answer.data;
		if(errors) {
			console.log(errors);
		}
		if(data) {
			commit('')
		}
	},
	getProfileInformation: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/model-settings/get')
			.then(response => {
				return response
			});
		if(answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		const {data, errors} = answer.data;
		if(errors) {
			console.log(errors);
		}
		if(data) {
			commit('setUsername', data.username);
			commit('setAge', data.age);
			commit('setEthnicOrigin', data.ethnicOrigin);
			commit('setSexualPreferences', data.sexualPreferences);
			commit('setNativeLanguage', data.nativeLanguage);
			commit('setMoreLanguage', data.moreLanguage);
			commit('setCountry', data.country);
			commit('setCity', data.city);
			commit('setGrowth', data.growth);
			commit('setBodyType', data.bodyType);
			commit('setEyeColor', data.eyeColor);
			commit('setButtSize', data.buttSize);
			commit('setWeight', data.weight);
			commit('setHairColor', data.hairColor);
			commit('setBreastSize', data.breastSize);
			commit('setIntimateHaircut', data.intimateHaircut);
			commit('setAbout', data.about);
			commit('setImage', data.image);
			commit('setTags', data.tags);
		}
	},
	updateModelPersonal: async ({commit}, credentials: Object): Promise<any> => {
		console.log(credentials);
		const answer = await apiUserData.put('/model/update-model-personal', credentials)
			.then(response => {
				return response
			});
		if(answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		const {data, errors} = answer.data;
		if(errors) {
			console.log(errors);
		}
		// console.log(data);
		if(data) {
			commit('setModelLogin', data.login);
			commit('setModelEmail', data.email);
			commit('setModelName', data.name);
			commit('setModelLastName', data.lastName);
			commit('setModelPhone', data.phone);
			commit('setModelCountry', data.country);
			commit('setModelRegion', data.region);
			commit('setModelCity', data.city);
			commit('setModelAddress', data.address);
			commit('setModelPostcode', data.postcode);
			commit('setModelDCMAProtect', data.dcmaProtect);
			commit('setModelEmailNotification', data.emailNotification);
			commit('setModelSpecialOffers', data.specialOffers);
			commit('setModelDocumentPhoto', data.documentPhoto);
			commit('setModelFacePhoto', data.facePhoto);
			commit('setModelFaceInDocPhoto', data.faceInDocPhoto);
			commit('setModelDocumentAvailable', data.documentAvailable);
			commit('setModelBirthday', data.birthday);
		}
	},
	getModelPersonal: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/model/get-model-personal')
			.then(response => {
				return response
			});
		if(answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		if(!answer.status) {
			console.log(answer.errors);
		}
		if(answer.data) {
			console.log("ModelAccount data", answer.data);
            commit('setModelData', answer.data);
			commit('setModelLogin', answer.data.login);
			commit('setModelEmail', answer.data.email);
			commit('setModelName', answer.data.name);
			commit('setModelLastName', answer.data.lastName);
			commit('setModelPhone', answer.data.phone);
			commit('setModelCountry', answer.data.country);
			commit('setModelRegion', answer.data.region);
			commit('setModelCity', answer.data.city);
			commit('setModelAddress', answer.data.address);
			commit('setModelPostcode', answer.data.postcode);
			commit('setModelDCMAProtect', answer.data.dcmaProtect);
			commit('setModelEmailNotification', answer.data.emailNotification);
			commit('setModelSpecialOffers', answer.data.specialOffers);
			commit('setModelDocumentPhoto', answer.data.documentPhoto);
			commit('setModelFacePhoto', answer.data.facePhoto);
			commit('setModelFaceInDocPhoto', answer.data.faceInDocPhoto);
			commit('setModelDocumentAvailable', answer.data.documentAvailable);
			commit('setModelBirthday', answer.data.birthday);

			//Роутинг на шаги регистрации
			if ((!answer.data.name && !answer.data.lastName) || !answer.data.birthday)
                router.push({name: 'model-account-contact-information'}).then(r => r);

		}
	},
	setPaymentInformation: async ({commit}, credentials: Object): Promise<any> => {
		const answer = await apiUserData.post('/model_settings/store', credentials)
			.then(response => {
				return response
			});
		// if(answer === undefined) {
		// 	console.log(answer);
		// }
		// console.log(answer);
		// const {data, errors} = answer.data;
		// if(errors) {
		// 	console.log(errors);
		// }

		//if(data) {
			// commit()
			//router.push({name: 'model-account-agreement-pp'}).then(r => r);
		//}
	},
	setOrderPayment: async ({commit}, credentials: Object): Promise<any> => {
		const answer = await apiUserData.post('', credentials)
			.then(response => {
				return response
			});
		if (answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		const {
			data,
			errors
		} = answer.data;
		if (errors) {
			console.log(errors);
		}
		if (data) {
			// commit()
			router.push({ name: '' }).then(r => r);
		}
	},
	renewConfidentiality: async ({commit}, credentials: Object): Promise<any> => {
		const answer = await apiUserData.post('/model/add-geo-block', credentials)
			.then(response => {
				return response
			});
		if(answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		// const {data, errors} = answer.data;
		// if(errors) {
		// 	console.log(errors);
		// }
		// if(data) {
		// 	// commit()
		// 	router.push({name: ''}).then(r => r);
		// }
	},
	getConfidentiality: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/model/add-geo-block')
			.then(response => {
				return response
			});
		if(answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		if(answer.data) {
			commit('');
		}
		// const {data, errors} = answer.data;
		// if(errors) {
		// 	console.log(errors);
		// }
		// if(data) {
		// 	// commit()
		// 	router.push({name: ''}).then(r => r);
		// }
	},
	updateModelAgreement: async({commit}, agreementValue): Promise<any> => {
		const answer = await apiUserData.put('', agreementValue)
			.then(response => {
				return response
			});
		if(answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
	},
	uploadModelsPhoto: async({commit}, photo): Promise<any> => {
		const answer = await apiUserData.post('/model/store-photos', photo)
			.then(response => {
				return response
			});
		console.log(answer);
		if(answer === undefined) {
			console.log(answer);
		}
		if(answer.data) {
			commit('setModelsPhotos', answer.data.image) //должен быть массив ссылок
		}
		if(!answer.status) {
			console.log(answer.errors);
		}
	},
	getModelsPhoto: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/model/get-photos-all')
			.then(response => {
				return response
			});
		if (answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		if (!answer.status) {
			console.log(answer.errors);
		}
		if(answer.data) {
			commit('setModelPhoto', answer.data)
		}
	},
	getAllTags: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/model/get-tags')
			.then(response => {
				return response
			});
		if (answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		if (!answer.status) {
			console.log(answer.errors);
		}
		if(answer.data) {
			commit('setModelSettingsTags', answer.data)
		}
	},
	uploadModelsVideo: async({commit}, video): Promise<any> => {
		const answer = await apiUserData.post('/model/store-photos', video)
			.then(response => {
				return response
			});
		console.log(answer);
		if(answer === undefined) {
			console.log(answer);
		}
		if(answer.data) {
			commit('setModelVideo', answer.data)
		}
		if(!answer.status) {
			console.log(answer.errors);
		}
	},
	getModelsVideo: async({commit}): Promise<any> => {
		const answer = await apiUserData.get('/model/store-photos')
			.then(response => {
				return response
			});
		console.log(answer);
		if(answer === undefined) {
			console.log(answer);
		}
		if(answer.data) {
			commit('setModelVideo', answer.data)
		}
		if(!answer.status) {
			console.log(answer.errors);
		}
	},
    getFormValues: async({commit}): Promise<any> => {

		const params = [
			'fields[]=sexual_preferences',
            'fields[]=ethnic_origin',
            'fields[]=height',
            'fields[]=weight',
            'fields[]=figure',
            'fields[]=hair_color',
            'fields[]=eye_color',
            'fields[]=breast_size',
            'fields[]=booty_size',
            'fields[]=intimate_haircut'
		];

		var queryParams = params.join("&");

        const answer = await apiUserData.get('/model/get-form-values?'+queryParams)
            .then(response => {
                return response
            });
        console.log(answer);
        if(answer === undefined) {
            console.log(answer);
        }
        if(answer.data) {
            commit('setFormValues', answer.data)
        }
        if(!answer.status) {
            console.log(answer.errors);
        }

      //  return answer;
    },
    getCountries: async({commit}): Promise<any> => {
        const answer = await apiUserData.get('/location/get-countries')
            .then(response => {
                console.log('getCountries response', response);
                return response
            });

        if(answer === undefined) {
            console.log(answer);
        }
        if(answer && answer.data) {
            commit('setCountriesData', answer.data)
        }
    },
    getRegions: async({commit}, country_id): Promise<any> => {
        const answer = await apiUserData.get('/location/get-regions?country_id='+country_id)
            .then(response => {
                console.log('getCountries response', response);
                return response
            });

        if(answer === undefined) {
            console.log(answer);
        }

        if(answer && answer.data) {
            commit('setRegionsData', answer.data)
        }
        if(!answer.status) {
            console.log(answer.errors);
        }
    },
    getCities: async({commit}, data): Promise<any> => {
        const answer = await apiUserData.get('/location/get-cities?country_id='+data.country_id+'&region_id='+data.region_id)
            .then(response => {
                console.log('getCities response', response);
                return response
            });

        if(answer === undefined) {
            console.log(answer);
        }
        if(answer.data) {
            commit('setCitiesData', answer.data)
        }
        if(!answer.status) {
            console.log(answer.errors);
        }
    },
    getAllLangs: async({commit}, data): Promise<any> => {
        const answer = await apiUserData.get('/language')
            .then(response => {
                return response
            });

        if(answer === undefined) {
            console.log(answer);
        }
        if(answer.data) {
        	console.log('getAllLangs data', answer.data);
            commit('setAllLangs', answer.data)
        }
        if(!answer.status) {
            console.log(answer.errors);
        }
    },
    updateModelInfo: async ({commit}, credentials: Object): Promise<any> => {
        const answer = await apiUserData.post('/model/store-model-info', credentials)
            .then(response => {
                console.log('saveModelInfo', answer);
                return response
            });

        if (answer === undefined)
            console.log(answer);

        const {data, errors} = answer.data;

        if (errors)
            console.log(errors);
    },
    saveFigureInfo: async ({commit}, credentials: Object): Promise<any> => {
        await apiUserData.post('/model/set-body-parameters', credentials).then(response => {
			return response
		});
    },
	getModelInfo: async({commit}, data): Promise<any> => {
        const answer = await apiUserData.get('/model/get-model-info')
            .then(response => {
                console.log('getModelInfo', answer);
                return response
            });

        if (answer === undefined)
            console.log(answer);

        if (answer.data)
            commit('setModelInfo', answer.data);

        if (!answer.status)
            console.log(answer.errors);
    },
    setModelTags: async ({commit}, credentials: Object): Promise<any> => {
        const answer = await apiUserData.post('/model/set-tags', credentials).then(response => {
			return response
		});
    },
    getModelTags: async ({commit}, id : number): Promise<any> => {
        const answer = await apiUserData.get('/model/get-tags?id='+id).then(response => {
            return response
        });
        if (answer.data)
            commit('setModelTags', answer.data);
    },



    getShopCategories: async ({commit}): Promise<any> => {
        const answer = await apiUserData.get('/shop/get-categories').then(response => {

            if (response.status)
                commit('setShopCategories', response.data);

            return response;
        });

        return answer.data;
    },

    updateProduct: async ({commit}, data: any): Promise<any> => {
		let getString = "";
		if (data.get && data.get.id)
            getString = "?id="+data.get.id;

        const answer = await apiUserData.post('/shop/update-product'+getString, data.post).then((response) => {
            return response;
        });

        return answer.data;
    },

    deleteProduct: async ({commit}, id : number): Promise<any> => {
        const answer = await apiUserData.delete('/shop/delete-product?id='+id).then(response => {

            return response;
        });

        return answer.data;
    },

    getModelProducts: async ({commit}, id : number): Promise<any> => {
        const answer = await apiUserData.get('/shop/get-model-products/'+id).then(response => {
            if (response && response.status)
                commit('setModelProducts', response.data);

            return response;
        });

        return answer.data;
    },
};
