/* eslint-disable */
import { MutationTree } from 'vuex';
import { BDSM } from '@/store/bdsm/types';

export const mutations: MutationTree<BDSM> = {
	setTime: (state, timeLeft: string) => {
		state.timeLeft = timeLeft;
	},
	setBDSMVideo: (state, video: Array<any>) => {
		state.arrVideo = video.slice();
	}
}
