/* eslint-disable */
import axios from 'axios'

const apiCustomer = axios.create({
	baseURL: "https://api.streampp24.com/v1",
	headers: {'content-type': 'application/json'}
});

export default {
	post: (path: string, body: any) => {
		return apiCustomer
			.post(path, body)
			.then((response: { data: any }) => response)
			.catch(error => error.response);
	},

	get: (path: string) => {
		return apiCustomer
			.get(path)
			.then(response => response.data)
			.catch(error => error.response);
	},

	put: (path: string, body: Object) => {
		return apiCustomer
			.post(path, body)
			.then((response: { data: any }) => response.data)
			.catch(error => error.response);
	},

	delete: (path: string) => {
		return apiCustomer
			.delete(path)
			.then(response => response.data)
			.catch(error => error.response);
	}
};
