/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { Messages } from '@/store/messages/types';
// import { messages } from '@/store/messages/index'

export const mutations: MutationTree<Messages> = {
	setTodayMessages: (state, todayMsg: Array<any>) => {
		state.todayMessages.concat(todayMsg);
	},
	setHistoryMessages: (state, historyMsg: Array<any>) => {
		state.historyMessages.concat(historyMsg);
	},
	setMessage: (state, myMessage: string) => {
		state.todayMessages.push(myMessage);
	},
	setMessageForReply: (state, msgReply: Array<any>) => {
		state.messageOnReply.concat(msgReply);
	},
	setLastMessage: (state, lastMsg: any) => {
		state.lastMessage = lastMsg;
	}
}
