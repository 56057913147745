/* eslint-disable */
import {ActionTree} from "vuex";
import {CustomerState} from "@/store/customer/types";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import routing from '@/route/routing';
import {getSessionStorageService, setLocalStorageService, setSessionStorageService} from '@/services/storage.service';

export const actions: ActionTree<CustomerState, RootState> = {
    logout: async ({commit}, credentials: FormData): Promise<any> => {
        sessionStorage.clear();
        localStorage.clear();
        routing.push({name: 'main'}).then(function () {
            routing.go(<any>routing.currentRoute);
        });
    },

	login: async ({commit}, credentials: FormData): Promise<any> => {
		const answer = await api.post('/auth/login', credentials)
			.then((response) => {
				console.log(response);
				return response;
			});

		if (answer === undefined) {
			commit('setError', ['Something went wrong...']);
			// return;
		}
		const {data, errors} = answer.data;
		if (errors.password) {
			// console.log(errors)
			commit('setRequestStatus', false);
			commit('setError', errors.password);
			// return;
		}

		if (data.length !== 0 && answer.status === 200) {
			commit('setRequestStatus', true);
			commit('saveToken', data.token);
			commit('setIsLogin', true);
			commit('setRole', data.role);

            setSessionStorageService('token', data.token);

			console.log("Всё ок, авторизируем...");

			if(getSessionStorageService('role') === '3') {
                console.log("Переходим в профиль пользователя...");

				routing.push({name: 'user-account'}).then(function () {
					console.log("After routing push...");
                    routing.go(<any>routing.currentRoute);
                });
                //routing.go(1);
			}
			if(getSessionStorageService('role') === '4') {
				routing.push({name: 'model-account-my-account'}).then(r => r);
			}
		}
	},

	register: async ({commit}, credentials: any): Promise<any> => {
		const answer = await  api.post('/auth/register', credentials)
			.then(response => {
				console.log(response);
				return response;
			});
		if (answer === undefined) {
			commit('setError', ['Something went wrong...']);
			return;
		}
		const {errors, data} = answer.data;

		if (errors.length > 0) {
			commit('setRequestStatus', false);
			commit('setError', errors);
			return;
		}

		if (data.length !== 0 && answer.status === 200) {
			commit('setRequestStatus', true);
			commit('saveToken', data.token);
			commit('setIsLogin', true);
			commit('setRole', data.role);
			routing.push({name: 'personal-account'}).then(r => r);
		}

	},

	fetchUserData: async ({commit}, token: string): Promise<any> => {
		const answer = await apiUserData.get('/user/info')
			.then(response => {
				return response;
			});

		if (answer === undefined) {
			console.log(answer);
		}

		if (answer)
		{
            const {data	, errors} = answer;

            if (answer && answer.status === true && answer.status !== 401) {
                commit('setLogin', data.login);
                commit('setTariff', data.tariff);
                commit('setEmail', data.email);
                commit('setFetchedUserData', true);
                commit('setUserData', data);
                commit('setIsLogin', true);
                console.log("Есть данные о юзере...");
                return;
            }
		}
		else
		{
            commit('setIsLogin', false);
            console.log("Ошибка получения данных о юзере...");
		}
	},

	fetchCount: async ({commit}, token: string): Promise<any> => {
		const answer = await apiUserData.get('/user/get-balance')
			.then(response => {
				return response;
			})
		if(answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		const {data	, errors} = answer;
		if(data && answer.status) {
			commit('setAccountMoney', data.balance);
		}
		if(errors.length > 0) {
			console.log(errors);
		}

	},


	changeNickName: async ({commit}, credentials: Object): Promise<any> => {
		const answer = await apiUserData.post('/user/change-nickname', credentials).then(response => {
			return response;
		});

		return answer.data;
	},

    changeEmail: async ({commit}, credentials: Object): Promise<any> => {
        const answer = await apiUserData.post('/user/change-email', credentials).then(response => {
            return response;
        });

        return answer.data;
    },

    changePhone: async ({commit}, credentials: Object): Promise<any> => {
        const answer = await apiUserData.post('/user/change-phone', credentials).then(response => {
            return response;
        });

        return answer.data;
    },

    changeAvatar: async ({commit}, credentials: Object): Promise<any> => {
        const answer = await apiUserData.post('/user/change-avatar', credentials).then(response => {
            return response;
        });

        return answer.data;
    },

    changePassword: async ({commit}, credentials: Object): Promise<any> => {
        const answer = await apiUserData.post('/auth/change-password', credentials).then(response => {
            return response;
        });

        return answer.data;
    },

    resetPassword: async ({commit}, credentials: Object): Promise<any> => {
        const answer = await apiUserData.post('/auth/reset-password', credentials).then(response => {
            return response;
        });

        return answer.data;
    },

    getUserSettings: async ({commit}): Promise<any> => {
        const answer = await apiUserData.get('/user/get-settings').then(response => {

            if (response.status)
                commit('setUserSettings', response.data);

            return response;
        });

        return answer.data;
    },

    updateUserSettings: async ({commit}, credentials: Object): Promise<any> => {
        const answer = await apiUserData.post('/user/update-settings', credentials).then(response => {
            return response;
        });

        return answer.data;
    },

    getBasket: async ({commit}): Promise<any> => {
        const answer = await apiUserData.get('/shop/get-basket').then(response => {

            if (response.status)
                commit('setBasket', response.data);

            return response;
        });

        if (answer)
        	return answer.data;
    },

    addToBasket: async ({commit}, credentials: Object): Promise<any> => {
        const answer = await apiUserData.post('/shop/add-to-basket', credentials).then(response => {
            return response;
        });

        if (answer)
        	return answer.data;
    },

    removeFromBasket: async ({commit}, id : number): Promise<any> => {
        const answer = await apiUserData.delete('/shop/remove-from-basket/'+id).then(response => {
            return response;
        });

        if (answer)
            return answer.data;
    },




	postPromoCode: async ({commit}, credentials: Object): Promise<any> => {
		const answer = await apiUserData.post('/user/activate-promocode', credentials)
			.then(response => {
				return response
			});
		if(answer === undefined) {
			commit('setError', ['Something went wrong...']);
			console.log(answer);
		}
		console.log(answer);

		const {data	, errors} = answer.data
		console.log(errors);

		if(errors.length === 0 && answer.status) {
			console.log(data);
			commit('setRequestStatus', true);
		}
		if (errors.length > 0) {
			// console.log(errors)
			commit('setRequestStatus', false);
			commit('setError', errors);
		}
	},

	cooperationPartner: async ({commit}, credentials: Object): Promise<any> => {
		const answer = await api.post('/partner/add-request', credentials)
			.then(response => {
				return response
			});
		console.log(answer);
		const {data, errors} = answer;
		errors ? console.log(errors) : false;
		data ? console.log(data) : false;
	},

	guestRequest: async ({commit}, credentials: Object): Promise<any> => {
		const answer = await api.post('/guest/add-request', credentials)
			.then(response => {
				return response
			});
		console.log(answer);
		const {data, errors} = answer;
		errors ? console.log(errors) : false;
		data ? console.log(data) : false;
	},

	storeBill: async ({commit}, pay: Object): Promise<any> => {
		const answer = await api.post('', pay)
			.then(response => {
				return response
			});
		console.log(answer);
		const {data, errors} = answer;
		errors ? console.log(errors) : false;
		data ? console.log(data) : false;
	},

	fetchFavorites: async ({commit}, token: string): Promise<any> => {
		const answer = await apiUserData.get('/user/get-favorite')
			.then(response => {
				return response;
			})
		if(answer === undefined) {
			console.log(answer);
		}
		console.log(answer);
		const {data	, errors} = answer;
		if(data) {
			return
		}
		if(errors) {
			console.log(errors);
		}
	},

	storeFavorites: async ({commit}, favorites: Object): Promise<any> => {
		const answer = await api.post('/user/add-favorite', favorites)
			.then(response => {
				return response
			});
		console.log(answer);
		const {data, errors} = answer;
		errors ? console.log(errors) : false;
		data ? console.log(data) : false;
	}
};
