/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { ControlTariff } from '@/store/tariff/types';

export const getters: GetterTree<ControlTariff, RootState> = {
	getTarifs: state => {
		return state.allTarifs;
	},
	getModelsTariff: state => {
		return state.models;
	},
	getCurrentViewModel: state => {
		return state.currentViewModel;
	},
	getSelectedModels: state => {
		return state.selectedModels;
	},
	getAmountResult: state => {
		return state.amountResult;
	},
	getMessageFromChat: (state) => {
		return state.messages;
	},
	getControlMpdels: state => {
		return state.controlModels;
	},
	getVtcTariff: state => {
		return state.allVtcTariff;
	},
	getSvtcTariff: state => {
		return state.allSvtcTariff;
	},
}
