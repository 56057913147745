/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { PPWebModels } from '@/store/ppWebModels/types';

export const mutations: MutationTree<PPWebModels> = {
	setPPModels: (state, models) => {

	},
	setCurrentModel: (state, model) => {
		state.currentModel = model;
	},
	setMessageToChat: (state, message: Array<any>) => {
		state.messages.concat(message);
	},
	setCurrentProduct: (state, product) => {
		state.currentViewProduct = product
	},
	setIsSwitchChat: (state, isSwitch: boolean) => {
		state.isSwitchChat = isSwitch;
	},
    setModelInfo: (state, data: any) => {
        state.modelInfo = data;
    },
}
