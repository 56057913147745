/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { ModelOnWork } from '@/store/modelOnWork/types';

export const actions: ActionTree<ModelOnWork, RootState> = {
	storeActionModel: async ({commit}, request: Object): Promise<any> => {
		const answer = await apiUserData.post('', request)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) console.log('Great!');
	},
	storeChatMessage: async ({commit}, message: Object): Promise<any> => {
		const answer = await apiUserData.post('', message)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer.data;
		if(data) commit('setMessageToChat', data)
		if(errors) console.log(errors);
	},
	fetchChatMessage: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer.data;
		if(data) commit('setMessageToChat', data)
		if(errors) console.log(errors);
	},
	startWebCamShow: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer.data;
		// if(data) commit('setMessageToChat', data)
		if(errors) console.log(errors);
	},

    startStream: async ({commit}): Promise<any> => {
        const answer = await apiUserData.get('/stream/start').then((response) => {
			return response;
		});

        if(answer.status)
            commit('setStreamID', answer.data.streamId);
	},

	streamProgress: async ({commit}, data: any): Promise<any> => {
        await apiUserData.post('/stream/progress?streamId='+data.get.streamId, data.post).then((response) => {
            return response;
        });
    },

    stopStream: async ({commit}, id: any): Promise<any> => {
        await apiUserData.get('/stream/stop?streamId='+id).then((response) => {
            return response;
        });
    },

    getChatMessages: async ({commit}, data: any): Promise<any> => {
        const answer = await apiUserData.get('/stream/get-messages?streamId='+data.idStream+'&messageId='+data.messageId).then((response) => {
            return response;
        });

        if (answer.status)
            commit('setChatMessages', answer.data);
    },

    sendChatMessages: async ({commit}, data: any): Promise<any> => {
        await apiUserData.post('/stream/send-message?streamId='+data.get.streamId, data.post).then((response) => {
            return response;
        });
    },

    getActions: async({commit}, streamId): Promise<any> => {
        const answer = await apiUserData.get('/stream/get-ordered-actions?streamId='+streamId).then(response => {
            return response;
        });

        if (answer.status)
            commit('setModelActions', answer.data);

        return answer;
    },

    actionCancel: async({commit}, orderId): Promise<any> => {
        const answer = await apiUserData.get('/stream/action-cancel?orderId='+orderId).then(response => {
            return response;
        });

        return answer;
    },

    actionStart: async({commit}, orderId): Promise<any> => {
        const answer = await apiUserData.get('/stream/action-start?orderId='+orderId).then(response => {
            return response;
        });

        return answer;
    },

    actionComplete: async({commit}, orderId): Promise<any> => {
        const answer = await apiUserData.get('/stream/action-complete?orderId='+orderId).then(response => {
            return response;
        });

        return answer;
    }
};
