/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { Shop } from '@/store/shop/types';

export const getters: GetterTree<Shop, RootState> = {
    shopModels: (state) => {
        return state.shopModels;
    },

	getArrModels: state => {
		return state.arrModel;
	},
	getModelProducts: state => {
		return state.modelProducts;
	},
	getChoisenModel: state => {
		return state.modelChosen;
	},
	getCurrentProduct: state => {
		return state.currentViewProduct;
	},
	getBasket: state => {
		return state.basket;
	},
	getAmount: state => {
		return state.amount;
	},
	getBasketLength: state => {
		return state.basket?.length;
	},
	getPaymentOrder: state => {
		return state.paymentOrder;
	},
	getDeliveryCountry: state => {
		return state.country;
	},
    deliveryCountries: state => {
        return state.deliveryCountries;
    }
}
