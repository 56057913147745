/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { WorldsWebModels } from '@/store/worldsWebModels/types';

export const getters: GetterTree<WorldsWebModels, RootState> = {
	getArrWebModels: (state) => {
		return state.arrWebModels;
	},
	getMessageFromChat: (state) => {
		return state.messages;
	},
	getModelInfo: (state) => {
		return state.cardModelInfo;
	},
	getServicesCard: (state) => {
		return state.modelServiceCard;
	},
	getModelsPhotos: (state) => {
		return state.modelsPhotos;
	},
	getItemNumber: (state) => {
		return state.itemPhoto;
	},
	getItemVideoNumber: (state) => {
		return state.itemVideo;
	},
	getModelsVideo: (state) => {
		return state.modelsVideo;
	},
    paginationInfo: (state) => {
        return state.pagination;
    },
}
