/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { SexMasterClass } from '@/store/sexMasterClass/types';
import router from '@/route/routing';

export const actions: ActionTree<SexMasterClass, RootState> = {
	getAllModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/master-class/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		if(answer) {
			commit('setStateAllModels', answer.data);
		}
	},
	getPriceVideo: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		if(answer) {
			commit('setStateAllModels', answer.data);
		}
	},
	payForVideo: async ({commit}, pay): Promise<any> => {
		const answer = await apiUserData.post('', pay)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		// if(answer.status) {
		// 	router.push({ name: 'sex-master-classes-video-viewing' }).then(r => r);
		// }
	},
};
