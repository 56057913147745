/*eslint-disable*/
import { createI18n } from 'vue-i18n';
import en from './assets/translations/en.json';
import ru from './assets/translations/ru.json';

export const i18n = createI18n({
	locale: localStorage.getItem('language') || 'en',
	messages: {
		ru: ru,
		en: en
	}
})
