/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { HisOwnDirector } from '@/store/hisOwnDirector/types'

export const getters: GetterTree<HisOwnDirector, RootState> = {
	getArrAllModels: (state) => {
		return state.arrAllModels;
	},
	getNumOfModels: (state) => {
		return state.numOfModels;
	}
}
