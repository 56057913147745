/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { HisOwnDirector } from '@/store/hisOwnDirector/types'

export const mutations: MutationTree<HisOwnDirector> = {
	setListModels: (state, arrModels: Array<any>) => {
		state.arrAllModels = arrModels.slice();
	},
	setNumOfModels: (state, number: number) => {
		state.numOfModels = number;
		setSessionStorageService('numOfModels', number);
	},
	setSelectedModelLength: (state, length: number) => {
		state.selectedModels.length = length;
	},
	setSelectedModels: (state, models) => {
		state.selectedModels = models;
		setSessionStorageService('selectedModels', JSON.stringify(models));
	}
}
