/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import apiUserData  from '@/services/getUserData.service';
import { BDSM } from '@/store/bdsm/types';
// import api from '@/services/api.service';
// import router from '@/route/routing';

export const actions: ActionTree<BDSM, RootState> = {
	storeModelTodo: async ({commit}, todo: string): Promise<any> => {
		const answer = await apiUserData.post('', todo)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		// const {data, errors} = answer;
		// if(answer.status) {
		//
		// }
		// if(errors) console.log(errors);
	},
	storeCameraPosition: async ({commit}, position: string): Promise<any> => {
		const answer = await apiUserData.post('', position)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		// const {data, errors} = answer;
		// if(answer.status) {
		//
		// }
		// if(errors) console.log(errors);
	},
	fetchBDSMVideo: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		const {data, errors} = answer;
		if(answer.status) {
			commit('setBDSMVideo', data);
		}
		if(errors) console.log(errors);
	},
	fetchBdsmModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/bdsm/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		const {data, errors} = answer.data;
		if(answer.status) {
			// console.log(data);
			// commit('setBDSMVideo', data);
		}
		if(errors) console.log(errors);
	},
	storeDonate: async ({commit}, donate: string): Promise<any> => {
		const answer = await apiUserData.post('', donate)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		// const {data, errors} = answer;
		// if(answer.status) {
		//
		// }
		// if(errors) console.log(errors);
	},
	storeBDSMViewing: async ({commit}, type: string): Promise<any> => {
		const answer = await apiUserData.post('', type)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) console.log('undefined');
		// const {data, errors} = answer;
		// if(answer.status) {
		//
		// }
		// if(errors) console.log(errors);
	},
};
