/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { PPWebModels } from '@/store/ppWebModels/types';

export const getters: GetterTree<PPWebModels, RootState> = {
	getPPModels: state => {
		return state.ppModels;
	},
	getCurrentModel: state => {
		return state.currentModel;
	},
	getCardModelInfo: state => {
		return state.cardModelInfo;
	},
	getMessageFromChat: (state) => {
		return state.messages;
	},
	getServicesCard: (state) => {
		return state.modelServiceCard;
	},
	getIsSwitchChat: state => {
		return state.isSwitchChat;
	},
	getModelInfo: (state) => {
		return state.modelInfo;
	},
}
