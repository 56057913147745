/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { PPWebModels } from '@/store/ppWebModels/types';
import router from '@/route/routing';

export const actions: ActionTree<PPWebModels, RootState> = {
	fetchPPModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/pp/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if (answer.status) {
			commit('setPPModels', data);
		}
		if (!answer.status) console.log(errors);
	},

    getModelInfoByUsername: async({commit}, username): Promise<any> => {
        console.log('getModelInfoByUsername', username);
        const answer = await apiUserData.get('/model/get-model/'+username)
            .then(response => {
                return response
            });

        if (answer.data)
            commit('setModelInfo', answer.data);

        if (!answer.status)
            console.log(answer.errors);
    },

    orderAction: async({commit}, data): Promise<any> => {
        const answer = await apiUserData.get('/stream/order-action?streamId='+data.streamId+"&actionId="+data.actionId).then(response => {
			return response;
		});

        return answer;
    },
};
