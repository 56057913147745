/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { Shop } from './types'
import {RootState} from '../types';
// import { getSessionStorageService } from '@/services/storage.service'

export const state: Shop = {
	arrModel: [
		// {
		// 	id: 0,
		// 	email: "test@test.com",
		// 	name: "Vanessa",
		// 	last_name: "Jebri",
		// },
		// {
		// 	id: 1,
		// 	email: "test@test.com",
		// 	name: "Janessa",
		// 	last_name: "Lebri",
		// },
		// {
		// 	id: 2,
		// 	email: "test@test.com",
		// 	name: "Anessa",
		// 	last_name: "Abri",
		// },
		// {
		// 	id: 3,
		// 	email: "test@test.com",
		// 	name: "Janessa",
		// 	last_name: "Jabri",
		// },
		// {
		// 	id: 4,
		// 	email: "test@test.com",
		// 	name: "Lanessa",
		// 	last_name: "Lebri",
		// },
		// {
		// 	id: 5,
		// 	email: "test@test.com",
		// 	name: "Agnessa",
		// 	last_name: "Gebri",
		// },
		// {
		// 	id: 6,
		// 	email: "test@test.com",
		// 	name: "Linessa",
		// 	last_name: "Bri",
		// }
	],
	modelChosen: {},
	basket: [
		// {
		// 	id: 0,
		// 	name: "Valery",
		// 	description: "Трусики",
		// 	image: "",
		// 	price: 300,
		// 	count: 1,
		// 	product_id: 0,
		// },
		// {
		// 	id: 1,
		// 	name: "Valery",
		// 	description: "Бюстгальтер",
		// 	image: "",
		// 	price: 300,
		// 	count: 1,
		// 	product_id: 2,
		// },
	],
	currentViewProduct: {},
	amount: 0,
	paymentOrder: {
		country: '',
		email:'',
		name: '',
		lastname: '',
		city: '',
		address: '',
		region: '',
		postcode: 0,
		delivery: ''
	},
	modelProducts: [],
	country: [],
    deliveryCountries: [],

    shopModels: []
};

const namespaced: boolean = true;

export const shop: Module<Shop, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
