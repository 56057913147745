/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {SexMasterClass} from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: SexMasterClass = {
	allModels: [
		{
			id: 0,
			name: 'Valya'
		},
		{
			id: 1,
			name: 'Vika'
		},
		{
			id: 2,
			name: 'Olga'
		},
		{
			id: 3,
			name: 'Elena'
		},
		{
			id: 4,
			name: 'Katya'
		},
	],
	videoSelectedModels: [1,2,3],
	priceVideo: parseInt(<string>getSessionStorageService('priceVideo')) || 1
};

const namespaced: boolean = true;

export const sexMasterClass: Module<SexMasterClass, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
