/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { Fetish } from '@/store/fetish/types';

export const getters: GetterTree<Fetish, RootState> = {
	getFetishCategory: state => {
		return state.fetishCategory;
	},
	getSelectedCategory: state => {
		return state.selectedCategory;
	},
	getFetishModels: state => {
		return state.fetishModels;
	},
	getNameModel: state => {
		return state.nameModel;
	},
	getFetishModelVideo: state => {
		return state.fetishModelVideo;
	},
	getBalance: state => {
		return state.balance
	}
}
