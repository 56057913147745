/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { ASMRModelChange } from '@/store/asmr/types';

export const mutations: MutationTree<ASMRModelChange> = {
	setAsmrModels: (state, models: Array<any>) => {
		state.arrModels = models.slice();
		console.log(state.arrModels);
	},
	setModelItem: (state, item: string) => {
		state.modelItem = item;
	},
	setModelVideo: (state, item) => {
		state.arrVideo = item.slice();
	}
}
