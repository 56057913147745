/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { VideoStories } from '@/store/videoStories/types'

export const getters: GetterTree<VideoStories, RootState> = {
	getModelLists: state => {
		return state.modelList;
	},
	getVideoList: state => {
		return state.modelVideoList;
	},
	getChosenModels: state => {
		return state.chosenModels;
	},
	getChosenVideo: state => {
		return state.chosenVideo;
	},
	getMyCount: state => {
		return state.myCount;
	}

}
