/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { ASMRModelChange } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: ASMRModelChange = {
	arrModels:[
		// 1,2,3,4,5,6,7,8,9
	],
	arrVideo: [
		// 1,2,3,4,5,6,7,8,9
	],
	modelItem: '',
	priceVideo: 1
};

const namespaced: boolean = true;

export const asmr: Module<ASMRModelChange, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
