/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import apiUserData  from '@/services/getUserData.service';
import { ASMRModelChange } from '@/store/asmr/types';
// import api from '@/services/api.service';
// import router from '@/route/routing';

export const actions: ActionTree<ASMRModelChange, RootState> = {
	getAsmrModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/asmr/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(answer.status) {
			commit('setAsmrModels', data);
		}
		if(errors) console.log(errors);
	},
	getAsmrModelsVideo: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/asmr/get-video')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer;
		if(answer.status) {
			commit('setModelVideo', data);
		}
		if(errors) console.log(errors);
	},
};
