/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import { WorldsWebModels } from './types'
import {RootState} from '../types';
import { getSessionStorageService } from '@/services/storage.service'

export const state: WorldsWebModels = {
	arrWebModels: [
		// 1,2,3,4,5,6,7,8,9,10,11,12
	],
	messages: [],
	cardModelInfo: {
// 		username: 'Valery',
// 		age: 21,
// 		sexualPreferences: 'Би',
// 		breastSize: 'маленький',
// 		ethnicOrigin: 'белая',
// 		country: 'Россия',
// 		language: 'Русский, Английский',
// 		about: `О себе
// \t\t\tp Turn On's: When a guy really shows how he feels, tells me what he enjoys and takes the time to discover what i like too
// \t\t\tp When A Guy Makes Me Feel Special & impresses me.
// \t\t\t<br>
// \t\t\tp Gifts! I LOVE Gifts .. see the New Gift Feature Below x
// \t\t\tp Also I LOVE C2C mmmmm
// \t\t\t<br>
// \t\t\tp MY FAN MEMBERS TEASING ME, RUBBING HARD COCK ARO...`
	},
	modelServiceCard: [
		// {
		// 	id: 0,
		// 	name: 'PUSSY',
		// 	price: 20
		// },
		// {
		// 	id: 1,
		// 	name: 'NAKED ASS',
		// 	price: 20
		// },
		// {
		// 	id: 2,
		// 	name: 'FINGERING ASS',
		// 	price: 20
		// },
		// {
		// 	id: 3,
		// 	name: 'FUCKING  PUSSY WITH A DILDO',
		// 	price: 20
		// },
		// {
		// 	id: 4,
		// 	name: 'LUSH and DOMI 333 SEC',
		// 	price: 20
		// },
		// {
		// 	id: 5,
		// 	name: 'FUCKING ASS WITH A DILDO',
		// 	price: 20
		// },

	],
	modelsPhotos: [
		// {
		// 	link: '/link4'
		// },
		// {
		// 	link: '/link5'
		// },
		// {
		// 	link: '/link6'
		// },
		// {
		// 	link: '/link7'
		// },
		// {
		// 	link: '/link8'
		// },
		// {
		// 	link: '/link9'
		// },
		// {
		// 	link: '/link10'
		// },
		// {
		// 	link: '/link11'
		// },
		// {
		// 	link: '/link12'
		// },
	],
	itemPhoto: 0,
	modelsVideo: [

	],
	itemVideo: 0,
	pagination: {}
};

const namespaced: boolean = true;

export const worldsWebModels: Module<WorldsWebModels, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
