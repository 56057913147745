/* eslint-disable */
import {createApp} from 'vue';
import App from './App.vue';
import routing from './route/routing';
import store from './store';
import './assets/sass/index.sass';
import 'bootstrap';
import './services/bootstrap.service';
import {i18n} from './i18n'

createApp(App).use(store).use(routing).use(i18n).mount('#app');
