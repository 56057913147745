/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { ControlTariff } from '@/store/tariff/types';

export const mutations: MutationTree<ControlTariff> = {
	setTariff: (state, tariff: Array<any>) => {
		state.allTarifs = tariff.slice() ?? [];
	},
	setModelsTariff: (state, models: Array<any>) => {
		state.models = models.slice() ?? [];
	},
	setCurrentViewModel: (state, model: Array<any>) => {
		state.currentViewModel.push(model);
	},
	setSelectedModels: (state, model: Object) => {
		state.selectedModels.push(model);
	},
	setAmountResult: (state, amount: number) => {
		state.amountResult = amount;
	},
	setMessageToChat: (state, message: Array<any>) => {
		state.messages.concat(message);
	},
	setVtcTariff: (state, tariff: Array<any>) => {
		state.allVtcTariff = tariff.slice() ?? [];
	},
	setSvtcTariff: (state, tariff: Array<any>) => {
		state.allSvtcTariff = tariff.slice() ?? [];
	}
}
