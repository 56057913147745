/* eslint-disable */
import axios from 'axios';
import { getLocalStorageService, getSessionStorageService } from '@/services/storage.service';

var apiUserData = axios.create({
	baseURL: "https://api.streampp24.com/v1",
	headers: {'Authorization': `Bearer ${getLocalStorageService('token') ?? getSessionStorageService('token')}`}
});

export default {
	get: (path: string) => {
        apiUserData = axios.create({
            baseURL: "https://api.streampp24.com/v1",
            headers: {'Authorization': `Bearer ${getLocalStorageService('token') ?? getSessionStorageService('token')}`}
        });

		return apiUserData
			.get(path)
			.then(response => response.data)
			.catch(error => error.response);
	},
	put: (path: string, body: any) => {
        apiUserData = axios.create({
            baseURL: "https://api.streampp24.com/v1",
            headers: {'Authorization': `Bearer ${getLocalStorageService('token') ?? getSessionStorageService('token')}`}
        });

		return apiUserData
			.put(path, body)
			.then((response: { data: any }) => response)
			.catch(error => error.response);
	},
	post: (path: string, body: any) => {
        apiUserData = axios.create({
            baseURL: "https://api.streampp24.com/v1",
            headers: {'Authorization': `Bearer ${getLocalStorageService('token') ?? getSessionStorageService('token')}`}
        });

		return apiUserData
			.post(path, body)
			.then((response: { data: any }) => response)
			.catch(error => error.response);
	},
    delete: (path: string) => {
        apiUserData = axios.create({
            baseURL: "https://api.streampp24.com/v1",
            headers: {'Authorization': `Bearer ${getLocalStorageService('token') ?? getSessionStorageService('token')}`}
        });

        return apiUserData
            .delete(path)
            .then(response => response.data)
            .catch(error => error.response);
    },
};
