/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import apiUserData  from '@/services/getUserData.service';
import { Fetish } from '@/store/fetish/types';
// import api from '@/services/api.service';
// import router from '@/route/routing';

export const actions: ActionTree<Fetish, RootState> = {
	fetchFetishModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/fetish/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer;
		if(answer.status) {
			commit('setFetishModels', data);
		}
		if(errors) console.log(errors);
	},
	fetchFetishCategories: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/fetish/get-categories')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer;
		if(answer.status) {
			// console.log(data);
			commit('setFetishCategory', data);
		}
		if(errors) console.log(errors);
	},
	fetchFetishModelVideo: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		const {data, errors} = answer;
		if(answer.status) {
			commit('setFetishModelVideo', data);
		}
		if(errors) console.log(errors);
	},
};
