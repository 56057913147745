/* eslint-disable */
import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import api from '@/services/api.service';
import apiUserData  from '@/services/getUserData.service';
import { SexRealityShow } from '@/store/sexRealityShow/types';

export const actions: ActionTree<SexRealityShow, RootState> = {
	fetchRealityShowModels: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('/sex-reality-show/get-models')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			commit('setRealityShowModels', data);
		}
	},
	fetchArchivesVideo: async ({commit}): Promise<any> => {
		const answer = await apiUserData.get('')
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined) {
			console.log('undefined');
		}
		const {data, errors} = answer;
		if(data) {
			commit('setArchivesVideo', data);
		}
	},
	storeModelViewingPerMinOrAll: async ({commit}, value: Object): Promise<any> => {
		const answer = await apiUserData.post('', value)
			.then((response) => {
				return response;
			});
		console.log(answer);
		if (answer === undefined)console.log('undefined');
		// const {data, errors} = answer.data;
		// if(data) commit('setMessageToChat', data)
		// if(errors) console.log(errors);
	},
};
