/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';
import { ModelOnWork } from '@/store/modelOnWork/types'

export const getters: GetterTree<ModelOnWork, RootState> = {
	getMessageFromChat: (state) => {
		return state.messages;
	},
	getSelectedAction: state => {
		return state.selectedAction;
	},
    getStreamID: state => {
        return state.idStream;
    },
    chatMessages: state => {
        return state.chatMessages;
    },
    modelActions: state => {
        return state.modelActions;
    }
}
