/* eslint-disable */
import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {CustomerState} from './types';
import {RootState} from '../types';
import { getLocalStorageService, getSessionStorageService } from '@/services/storage.service';

export const state: CustomerState = {
	messages: [],
	language: getLocalStorageService('language')|| process.env.VUE_APP_I18N_LOCALE || 'en',
	accountMoney: 0,
	login: getLocalStorageService('login') || getSessionStorageService('login') || '',
	tariff: {},
	role: JSON.parse(<string>getLocalStorageService('role')) || 0,
	favorites: [
		{
			type: '',
			timeLeft: {
				hours: 0,
				minutes: 0
			},
			timeIsUp: false,
			recordingOf: '',
			link: ''
		}
	],
	balanceReplenishment: '100',
	cryptoCurrency: [
		'Bitcoin (BTC)',
		'Ethereum (ETH)',
		'Litecoin (LTC)',
		'Cardano (ADA)',
		'Chainlink (LINK)',
		'XRP (Ripple)',
		'Bitcoin Cash (BCH)',
		'Tron (TRX)'
	],
	eWallet: [
		'Webmoney',
		'Webmoney',
		'Webmoney',
		'Webmoney',
		'Webmoney',
		'Webmoney',
		'Webmoney',
		'Webmoney'
	],
	typeWallet: '',
	typeCrypto: '',
	isAgreement: false,
	isRememberPassword: false,
	isLogin: JSON.parse(<string>getSessionStorageService('isLogin')) || false,
	isLoginChanged: false,
	isEmailChanged: false,
	isPasswordChanged: false,
	requestStatus: false,
	isRecoveryPassword: JSON.parse(<string>getSessionStorageService('isRecoveryPassword')) || true,
	isFetchedUserData: false,
    email: "",
	userData: "",
	userSettings: {},
    basket: [],
};

const namespaced: boolean = true;

export const customer: Module<CustomerState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
