/* eslint-disable */
import { GetterTree } from 'vuex';
import { CustomerState } from '@/store/customer/types';
import { RootState } from '@/store/types';
import { getSessionStorageService } from '@/services/storage.service';

export const getters: GetterTree<CustomerState, RootState> = {
	getLanguage(state): string {
		return state.language.toLowerCase();
	},
	getMessages: state => {
		return state.messages;
	},
	getRole(state) {
		return state.role;
	},
	getIslogin: (state): boolean => {
		return state.isLogin
	},
	getAccountMoney: (state): number => {
		return state.accountMoney;
	},
	getBalanceReplenishment: state => {
		return state.balanceReplenishment;
	},
	getCryptoCurrency: state => {
		return state.cryptoCurrency;
	},
	getTypeOfCrypto: state => {
		return state.typeCrypto;
	},
	getEWallet: state => {
		return state.eWallet
	},
	getTypeWallet: state => {
		return state.typeWallet;
	},
	getRequestStatus: state => {
		return state.requestStatus;
	},
	getLogin: state => {
		return state.login;
	},
	getIsRecoveryPassword: state => {
		return state.isRecoveryPassword;
	},
	getFetchedUserData: state => {
		return state.isFetchedUserData;
	},
    getUserData: state => {
        return state.userData;
    },
    getEmail: state => {
        return state.email;
    },
    userSettings: state => {
        return state.userSettings;
    },
    basket: state => {
        return state.basket;
    }
}
