/* eslint-disable */
import { MutationTree } from 'vuex';
import { setLocalStorageService, setSessionStorageService } from '@/services/storage.service';
import { VideoStories } from '@/store/videoStories/types';

export const mutations: MutationTree<VideoStories> = {
	setListModels: (state, models: Array<any>) => {
		state.modelList = models.slice();
	},
	setVideoList: (state, videos: Array<any>) => {
		state.modelVideoList = videos.slice();
	},
	setChosenModels: (state, model: Object) => {
		state.chosenModels = model;
	},
	setChosenVideo: (state, video: Object) => {
		state.chosenVideo = video;
	}
}
